import classNames from 'classnames/bind';
import { ReactNode, useMemo } from 'react';
import {
    CellProps,
    Column,
    ColumnInstance,
    Filters,
    IdType,
    Row,
    SortByFn,
    SortingRule,
    useExpanded,
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from 'react-table';

import { ChevronDown, ChevronUp } from '../../icons';
import Checkbox from '../Checkbox';
import Pagination from '../Pagination';
import {
    ColumnSelect,
    ExpandableRowToggle,
    NumRowsPerPage,
    ResultsPerPageSelect,
    RowRange,
} from './components';
import DownloadAsCsv from './components/DownloadAsCsv/DownloadAsCsv';
import classes from './ConfigurableTable.module.scss';

const cx = classNames.bind(classes);

export interface ChildrenProps<T extends object = {}> {
    /** An array of column instances */
    allColumns: ColumnInstance<T>[];
    /** The current column filter values. An array of objects, each having a column id and current filter value */
    currentColumnFilters: { id: IdType<T>; value: any }[];
    /** Any arbitrary value set as the global filter value */
    currentGlobalFilterValue: any;
    /** Boolean indicating if all rows are expanded */
    isAllRowsExpanded: boolean;
    /** The array of rows right before filtering. Useful for building option lists in filters. */
    preFilteredRows: Row<T>[];
    /** Array of all data after being filtered and sorted */
    processedData: T[];
    /** An array of data based on the selected rows */
    selectedData: T[];
    /** An array of objects with column id and filter value. Pass an empty array to clear all column filters */
    setAllColumnFilters: (filters: { id: IdType<T>; value: any }[]) => void;
    /** Filter based upon the column ID and the data in specific columns */
    setColumnFilter: (columnId: IdType<T>, filterValue: any) => void;
    /** set any arbitrary value as the global filter value */
    setGlobalFilterValue: (filterValue: any) => void;
    /** Programmatically set the sortBy for the table */
    setSortBy: (
        sortBy: {
            desc?: boolean;
            id: IdType<T>;
        }[]
    ) => void;
    /** Set or toggle wether all rows are expanded or not */
    toggleAllRowsExpanded: (value?: boolean) => void;
}

export interface ConfigurableTableProps<T extends object = {}> {
    /** A render prop for creating additional UI for the table e.g. filter menu */
    children?: (props: ChildrenProps<T>) => ReactNode;
    /** An array of `Column` objects - Must be memoized */
    columns: Column<T>[];
    /** Add additional `filterTypes` for column or global filtering - Must be memoized */
    customFilterTypes?: Record<
        string,
        (rows: Row<T>[], columnIds: IdType<T>[], filterValue: any) => Row<T>[]
    >;
    /** Add additional `sortTypes` for column sorting - Must be memoized */
    customSortTypes?: Record<string, SortByFn<T>>;
    /** An array of data objects - Must be memoized */
    data: T[];
    /** An array of objects that specify the default filtered state by column - Must be memoized */
    defaultColumnFilters?: Filters<T>;
    /** Global filter value can be any arbitrary value but be aware of what type of value your filterTypes will accept. Best used with a custom globalFilter - Must be memoized */
    defaultGlobalFilterValue?: any;
    /** An array of column id strings */
    defaultHiddenColumns?: IdType<T>[];
    /** An array of objects that specify the column id and sort direction - Must be memoized */
    defaultSortBy?: SortingRule<T>[];
    /** Can the table be downloaded in CSV format */
    downloadable?: boolean;
    /** Name to give downloaded csv file (don't supply file extension). Defaults to `key-download-<timestamp>` */
    downloadFilename?: string;
    /** Are rows allowed to expand to reveal nested rows */
    expandable?: boolean;
    /** Filter based upon your own arbitrary needs across any column in a given row - Must be memoized if a function */
    globalFilter?:
        | ((rows: Row<T>[], columnIds: IdType<T>[], filterValue: any) => Row<T>[])
        | string;
    /** How many rows to show per page. This can be changed by the user. */
    rowsPerPage?: NumRowsPerPage;
    /** Are the rows selectable */
    selectable?: boolean;
    /** Array of column ID's that should stick when scrolling horizontally */
    stickyColumns?: string[];
}

/**
 * NOTE:
 * rows - An array of filtered and sorted rows,
 * page - An array of rows for the current page
 */

export default function ConfigurableTable<T extends object = {}>({
    columns,
    data,
    defaultHiddenColumns = [],
    defaultSortBy = [],
    rowsPerPage = 20,
    selectable,
    children,
    defaultColumnFilters = [],
    globalFilter = 'text',
    customFilterTypes,
    customSortTypes,
    defaultGlobalFilterValue,
    stickyColumns = [],
    expandable = false,
    downloadable = false,
    downloadFilename,
}: ConfigurableTableProps<T>) {
    const stuckColumns = useMemo(() => {
        const columns = [...stickyColumns];

        if (selectable) {
            columns.push('selection');
        }

        return columns;
    }, [selectable, stickyColumns]);

    const sortTypes = useMemo<Record<string, SortByFn<T>>>(() => {
        return {
            /**
             * Overwrite the datetime sort function as it doesn't account for nullable
             * values. Fix below is taken from v8 lib.
             *
             * @src https://github.com/TanStack/table/pull/4110
             */
            datetime: (rowA, rowB, columnId) => {
                const a = rowA.values[columnId];
                const b = rowB.values[columnId];

                // Can handle nullish values
                // Use > and < because == (and ===) doesn't work with
                // Date objects (would require calling getTime()).
                // eslint-disable-next-line no-nested-ternary
                return a > b ? 1 : a < b ? -1 : 0;
            },
            ...customSortTypes,
        };
    }, [customSortTypes]);

    const {
        allColumns,
        getTableBodyProps,
        getTableProps,
        gotoPage,
        headerGroups,
        isAllRowsExpanded,
        nextPage,
        page,
        pageCount,
        preFilteredRows,
        prepareRow,
        previousPage,
        rows,
        selectedFlatRows,
        setAllFilters,
        setFilter,
        setGlobalFilter,
        setPageSize,
        setSortBy,
        state: { filters, globalFilter: globalFilterValue, pageIndex, pageSize },
        toggleAllRowsExpanded,
        toggleHideColumn,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: defaultHiddenColumns,
                sortBy: defaultSortBy,
                pageSize: rowsPerPage,
                pageIndex: 0,
                filters: defaultColumnFilters,
                globalFilter: defaultGlobalFilterValue,
            },
            filterTypes: customFilterTypes,
            globalFilter,
            sortTypes,
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        ({ visibleColumns }) => {
            if (selectable) {
                visibleColumns.push((columns) => [
                    {
                        // Create the select column
                        id: 'selection',
                        width: 49,
                        Header: ({ getToggleAllRowsSelectedProps }) => {
                            const props = getToggleAllRowsSelectedProps();
                            const { checked, onChange, title } = props;

                            return (
                                <Checkbox
                                    defaultChecked={checked}
                                    hideLabel
                                    label={title}
                                    name="select-all"
                                    onChange={onChange}
                                    value=""
                                />
                            );
                        },
                        Cell: (cell: CellProps<T>) => {
                            const { getToggleRowSelectedProps, id, isSelected } = cell.row;
                            const { onChange } = getToggleRowSelectedProps();

                            return (
                                <Checkbox
                                    checked={isSelected}
                                    controllable
                                    hideLabel
                                    label={`Toggle Row ${id} Selected`}
                                    name="select-row"
                                    onChange={(e) => {
                                        if (onChange) {
                                            onChange(e);
                                        }
                                    }}
                                    value=""
                                />
                            );
                        },
                    },
                    ...columns,
                ]);
            }
        }
    );

    const selectedData = useMemo(() => {
        return selectedFlatRows.map((row) => row.original);
    }, [selectedFlatRows]);

    const processedData = useMemo(() => {
        return rows.map((row) => row.original);
    }, [rows]);

    const currentPage = pageIndex + 1;
    const shouldShowFooter = pageCount > 1 || downloadable;

    return (
        <>
            {children
                ? children({
                      preFilteredRows,
                      currentColumnFilters: filters,
                      selectedData,
                      setSortBy,
                      allColumns,
                      setColumnFilter: setFilter,
                      setAllColumnFilters: setAllFilters,
                      currentGlobalFilterValue: globalFilterValue,
                      setGlobalFilterValue: setGlobalFilter,
                      processedData,
                      toggleAllRowsExpanded,
                      isAllRowsExpanded,
                  })
                : null}
            <div className={classes.wrapper}>
                <div className={classes['scroll-container']}>
                    <table
                        {...getTableProps({ className: classes.table })}
                        aria-rowcount={rows.length}
                    >
                        <thead>
                            {headerGroups.map((headerGroup) => {
                                const { getHeaderGroupProps, headers } = headerGroup;
                                const { key, ...restHeaderGroupProps } = getHeaderGroupProps();

                                return (
                                    <tr key={key} {...restHeaderGroupProps}>
                                        {headers.map((column) => {
                                            const { key, ...restColumn } = column.getHeaderProps();
                                            const {
                                                Header,
                                                fixedWidth,
                                                getSortByToggleProps,
                                                id,
                                                isSorted,
                                                isSortedDesc,
                                                render,
                                                totalLeft,
                                                width,
                                            } = column;
                                            const sortDirection = isSortedDesc
                                                ? 'descending'
                                                : 'ascending';
                                            const { onClick, style } = getSortByToggleProps();
                                            const title =
                                                typeof Header === 'string'
                                                    ? `Toggle ${Header} SortBy`
                                                    : 'Toggle SortBy';
                                            const isStickyColumn = stuckColumns.includes(id);
                                            // If a column is sticky or we specify it has a fixed width, we need to set a width. Default width is 150.
                                            const columnWidth =
                                                isStickyColumn || fixedWidth ? width : undefined;

                                            return (
                                                <th
                                                    key={key}
                                                    {...restColumn}
                                                    aria-sort={isSorted ? sortDirection : undefined}
                                                    className={cx({
                                                        sticky: isStickyColumn,
                                                    })}
                                                    onClick={onClick}
                                                    style={{
                                                        ...style,
                                                        left: totalLeft,
                                                        width: columnWidth,
                                                    }}
                                                    title={title}
                                                >
                                                    <div
                                                        className={cx('cell')}
                                                        style={{
                                                            width: columnWidth,
                                                        }}
                                                    >
                                                        <div className={cx('cell__content')}>
                                                            {render('Header')}
                                                        </div>

                                                        {isSorted ? (
                                                            <span
                                                                aria-hidden
                                                                className={
                                                                    classes['sort-indicator']
                                                                }
                                                            >
                                                                {isSortedDesc ? (
                                                                    <ChevronDown
                                                                        height={7}
                                                                        width={14}
                                                                    />
                                                                ) : (
                                                                    <ChevronUp
                                                                        height={7}
                                                                        width={14}
                                                                    />
                                                                )}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </th>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                const { key, ...restRowProps } = row.getRowProps();

                                return (
                                    <tr key={key} {...restRowProps}>
                                        {row.cells.map((cell, index) => {
                                            const { column, getCellProps, row } = cell;
                                            const { fixedWidth, id, totalLeft, width, wrap } =
                                                column;
                                            const { key, ...restCellProps } = getCellProps();
                                            const isStickyColumn = stuckColumns.includes(id);
                                            const {
                                                canExpand,
                                                depth,
                                                isExpanded,
                                                toggleRowExpanded,
                                            } = row;

                                            // Add the expand toggle to first column as long as it's not the selectable column
                                            const targetColumn = selectable ? 1 : 0;

                                            // If a column is sticky or we specify it has a fixed width, we need to set a width. Default width is 150.
                                            const columnWidth =
                                                isStickyColumn || fixedWidth ? width : undefined;

                                            return (
                                                <td
                                                    className={cx({
                                                        sticky: isStickyColumn,
                                                    })}
                                                    key={key}
                                                    style={{
                                                        left: totalLeft,
                                                        width: columnWidth,
                                                    }}
                                                    {...restCellProps}
                                                >
                                                    <div
                                                        className={cx('cell')}
                                                        style={{
                                                            width: columnWidth,
                                                        }}
                                                    >
                                                        <div
                                                            className={cx('cell__content', {
                                                                'cell__content--wrap': wrap,
                                                            })}
                                                        >
                                                            {index === targetColumn &&
                                                            expandable ? (
                                                                <ExpandableRowToggle
                                                                    canExpand={canExpand}
                                                                    depth={depth}
                                                                    isExpanded={isExpanded}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        toggleRowExpanded();
                                                                    }}
                                                                >
                                                                    {cell.render('Cell')}
                                                                </ExpandableRowToggle>
                                                            ) : (
                                                                cell.render('Cell')
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <ColumnSelect<T> options={allColumns} toggleHideColumn={toggleHideColumn} />
            </div>
            {shouldShowFooter ? (
                <div className={classes.footer}>
                    <div className={classes.footer__details}>
                        {downloadable && rows.length >= 1 && (
                            <DownloadAsCsv
                                fileName={downloadFilename}
                                json={rows.map((row) => {
                                    /**
                                     * "rows" is a filtered and sorted array the same way the table is at the time of
                                     * clicking the "Download as CSV" button
                                     *
                                     * "values" is an object representing the columns/values of the row. The value of
                                     * each column is what's returned from  the "accessor" attribute on the columns
                                     * array passed to the table.
                                     */
                                    return row.values;
                                })}
                            />
                        )}

                        {pageCount > 1 ? (
                            <>
                                <ResultsPerPageSelect
                                    defaultValue={rowsPerPage}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                    totalNumRows={rows.length}
                                />

                                {/* <JumpToPage
                                    defaultValue={`${currentPage}`}
                                    onChange={(e) => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                        gotoPage(page);
                                    }}
                                /> */}

                                <RowRange
                                    currentPage={currentPage}
                                    rowsPerPage={pageSize}
                                    totalRows={rows.length}
                                />
                            </>
                        ) : null}
                    </div>

                    {pageCount > 1 ? (
                        <Pagination
                            aria-label="Table Pagination"
                            currentPage={currentPage}
                            onGoTo={(pageNum) => gotoPage(pageNum - 1)}
                            onNext={nextPage}
                            onPrev={previousPage}
                            totalNumPages={pageCount}
                        />
                    ) : null}
                </div>
            ) : null}
        </>
    );
}
