/**
 * Determine if the token is still valid based on the expiry time.
 *
 * @param {string | number | null} expiry Value from localstorage in seconds
 */
export default function isTokenExpired(expiry: string | number | null) {
    const now = Math.round(Date.now() / 1_000);

    if (expiry === null) {
        return true;
    }

    const expiryTime = typeof expiry === 'string' ? parseInt(expiry, 10) : expiry;

    return now > expiryTime;
}
