import classNames from 'classnames/bind';
import { PropsWithChildren } from 'react';

import classes from './FormGroup.module.scss';

const cx = classNames.bind(classes);

interface props {
    /** Custom css className */
    className?: string;
}

export type FormGroupProps = PropsWithChildren<props>;

export default function FormGroup({ children, className }: FormGroupProps) {
    return (
        <div className={cx('form-group', className)} data-testid="form-group">
            {children}
        </div>
    );
}
