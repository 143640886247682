import classes from './RowRange.module.scss';

export interface RowRangeProps {
    currentPage: number;
    rowsPerPage: number;
    totalRows: number;
}

export default function RowRange({ currentPage, rowsPerPage, totalRows }: RowRangeProps) {
    const rangeEnd = currentPage * rowsPerPage;
    const rangeStart = rangeEnd - rowsPerPage + 1;

    return (
        <div
            aria-label={`Displaying ${rangeStart} to ${rangeEnd} results of ${totalRows}`}
            className={classes.wrapper}
        >
            Showing{' '}
            <strong>
                {rangeStart} - {rangeEnd}
            </strong>{' '}
            of {totalRows}
        </div>
    );
}
