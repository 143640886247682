import { Claims, User } from '@keyliving/shared-types';

export enum ORGS {
    clover = 'clover',
    key = 'key',
}

export interface AuthState {
    claims: Claims | null;
    token: string | null;
    user: User | null;
}

export interface SignInParams {
    email: string;
    password: string;
}
