import { ChangeEventHandler, FocusEventHandler, forwardRef, ReactNode } from 'react';

import classes from './RangeSlider.module.scss';

export interface RangeSliderProps {
    /** Default value of the slider */
    defaultValue?: string | number;
    /** Function to format the display of the min and max values under the slider */
    formatRangeValues?: (value?: string | number) => ReactNode;
    /** The maximum value in the range of permitted values */
    max?: string | number;
    /** The minimum value in the range of permitted values*/
    min?: string | number;
    /** Name attribute for the input */
    name: string;
    /** Function to be called on the onBlur event */
    onBlur?: FocusEventHandler<HTMLInputElement>;
    /** Function to be called on the onChange event */
    onChange?: ChangeEventHandler<HTMLInputElement>;
    /** Specifies the granularity that the value must adhere to */
    step?: number;
}

const RangeSlider = forwardRef<HTMLInputElement, RangeSliderProps>(
    (
        {
            max,
            min,
            onChange,
            formatRangeValues = (value) => value,
            step = 50,
            name,
            onBlur,
            defaultValue,
        },
        ref
    ) => {
        return (
            <div className={classes.wrapper}>
                <input
                    defaultValue={defaultValue}
                    max={max}
                    min={min}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    step={step}
                    type="range"
                />

                <div className={classes['range-values']}>
                    <div className={classes['range-values__value']}>{formatRangeValues(min)}</div>
                    <div className={classes['range-values__value']}>{formatRangeValues(max)}</div>
                </div>
            </div>
        );
    }
);

export default RangeSlider;
