import { GroupBase, StylesConfig } from 'react-select';

import { SearchableSelectOption } from './SearchableSelect';

export const STYLES: StylesConfig<
    SearchableSelectOption,
    boolean,
    GroupBase<SearchableSelectOption>
> = {
    // NOTE: Inline styles aren't ideal but is react-selects prefered method as it uses @emotion and less mess than trying to replicate all the current styles just to replace a few
    option: () => ({}),
    menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
    control: () => ({}),
    placeholder: () => ({}),
    input: (styles) => ({
        ...styles,
        fontSize: '0.875rem',
        lineHeight: 1.5,
        marginTop: 0,
        marginBottom: 0,
        padding: '0.75rem 0',
    }),
    valueContainer: (styles) => ({
        ...styles,
        padding: '0 0.75rem',
    }),
    multiValueLabel: () => ({}),
    multiValue: () => ({}),
};
