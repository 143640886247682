import { forwardRef } from 'react';

import { IconProps } from './types';

const LockIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ height = 24, width = 24, ...rest }, ref) => {
        return (
            <svg
                height={height}
                ref={ref}
                viewBox="0 0 24 24"
                width={width}
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                {...rest}
            >
                <rect
                    fill="none"
                    height="13.5"
                    rx="1.5"
                    ry="1.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    width="16.5"
                    x="3.75"
                    y="9.75"
                />
                <path
                    d="M6.75 9.75V6a5.25 5.25 0 0 1 10.5 0v3.75M12 15v3"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </svg>
        );
    }
);

export default LockIcon;
