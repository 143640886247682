import classNames from 'classnames/bind';
import { useCallback, useEffect } from 'react';

import classes from './Toast.module.scss';

const cx = classNames.bind(classes);

export enum TOAST_TYPE {
    ERROR = 'Error',
    INFO = 'Info',
    SUCCESS = 'Success',
}

export interface ToastProps {
    dismiss: (id: string) => void;
    id: string;
    message: string;
    type: TOAST_TYPE;
}

export default function Toast({ dismiss, id, message, type }: ToastProps) {
    const handleRemove = useCallback(() => {
        dismiss(id);
    }, [dismiss, id]);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleRemove();
        }, 5000);

        return () => {
            clearTimeout(timer);
        };
    }, [handleRemove]);

    return (
        <div
            aria-label={type}
            aria-live="polite"
            className={cx('toast', `toast--${type.toLowerCase()}`)}
        >
            <div className={classes.toast__content}>{message}</div>
            <button
                aria-label="Dismiss notification"
                className={classes.toast__action}
                onClick={handleRemove}
                type="button"
            >
                close
            </button>
        </div>
    );
}
