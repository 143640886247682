import classNames from 'classnames/bind';
import { ReactNode } from 'react';

import ErrorMessage from '../ErrorMessage';
import { checkForInputErrors } from '../ErrorMessage/lib';
import { ValidationErrors } from '../ErrorMessage/types';
import FormGroup from '../FormGroup';
import classes from './InputGroup.module.scss';

const cx = classNames.bind(classes);

interface ChildProps {
    errorId: string;
    isInvalid: boolean;
    labelId: string;
}
export interface InputGroupProps {
    /** Sub text displayed above the input and below the label */
    caption?: string;
    /** A valid ReactNode */
    children: (props: ChildProps) => ReactNode;
    /** Additional CSS classNames */
    className?: string;
    /** The errors object from useForm */
    errors?: ValidationErrors;
    /** Hide label visually. Still available to screen readers */
    hideLabel?: boolean;
    /** Label for the input */
    label: string;
    /** Name attribute for the input */
    name: string;
    /** Set the required attribute on the input and add a * beside the label */
    required?: boolean;
    /** Muted text displayed next to the label */
    secondaryLabel?: string;
}

export default function InputGroup({
    caption,
    children,
    className,
    errors,
    hideLabel = false,
    label,
    name,
    required,
    secondaryLabel,
}: InputGroupProps) {
    // TODO: When migrate to React 18 use the useId hook
    const { errorId, isInvalid } = checkForInputErrors({ errors, name });
    const labelId = `label-${name}`;

    return (
        <FormGroup className={className}>
            <label
                className={cx('label', { 'sr-only': hideLabel })}
                htmlFor={name}
                id={`label-${name}`}
            >
                {label}{' '}
                {secondaryLabel ? (
                    <span className={cx('secondary-label')}>{secondaryLabel}</span>
                ) : null}
                {required ? <span className={cx('required-indicator')}>&nbsp;*</span> : null}
            </label>
            {caption ? <div className={classes.caption}>{caption}</div> : null}
            {children({ errorId, labelId, isInvalid })}
            {errors ? <ErrorMessage errors={errors} id={errorId} name={name} /> : null}
        </FormGroup>
    );
}
