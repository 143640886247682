import { v4 as uuidv4 } from 'uuid';

import { getCookie, setCookie } from '../cookies';

export default function getOrCreateUUIDCookie(): string | undefined {
    if (typeof window === 'undefined') {
        return undefined;
    }

    const keyAnonId = getCookie('x-key-anon-id');

    if (!keyAnonId) {
        const uuid = uuidv4();
        setCookie('x-key-anon-id', uuid);

        return uuid;
    }

    return keyAnonId;
}
