import { useCallback, useReducer } from 'react';
import { FileRejection } from 'react-dropzone';

import ErrorMessage from '../ErrorMessage';
import { checkForInputErrors } from '../ErrorMessage/lib';
import FileDropArea, { FileDropAreaProps } from '../FileDropArea';
import classes from './FileUpload.module.scss';
import transformFileToUploadedFile, {
    transformFileRejectionToRejectedFile,
    transformUploadFailureToRejectedFile,
} from './lib/fileTransformer';
import RejectedList from './RejectedList';
import { Action, State } from './types';
import UploadedList from './UploadedList';

const INPUT_NAME = 'network';

export enum FileUploadActions {
    ADD_FILES_TO_REJECTED_LIST = 'ADD_FILES_TO_REJECTED_LIST',
    CLEAR_REJECTED_LIST = 'CLEAR_REJECTED_LIST',
    CLEAR_UPLOADED_LIST = 'CLEAR_UPLOADED_LIST',
    REMOVE_FILE_FROM_REJECTED_LIST = 'REMOVE_FILE_FROM_REJECTED_LIST',
    REMOVE_FILE_FROM_UPLOADED_LIST = 'REMOVE_FILE_FROM_UPLOADED_LIST',
    UPLOAD_DONE = 'UPLOAD_DONE',
    UPLOAD_ERROR = 'UPLOAD_ERROR',
    UPLOAD_FAILURE = 'UPLOAD_FAILURE',
    UPLOAD_PROGRESS = 'UPLOAD_PROGRESS',
    UPLOAD_START = 'UPLOAD_START',
    UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
}

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case FileUploadActions.UPLOAD_START:
            return {
                ...state,
                isUploading: true,
            };
        case FileUploadActions.UPLOAD_PROGRESS:
            return {
                ...state,
                percentComplete: action.payload.percent,
            };
        case FileUploadActions.UPLOAD_SUCCESS:
            return {
                ...state,
                uploadedFiles: [
                    ...transformFileToUploadedFile(action.payload.files),
                    ...state.uploadedFiles,
                ],
            };
        case FileUploadActions.UPLOAD_ERROR:
            return {
                ...state,
                isUploading: false,
                errors: action.payload,
            };
        case FileUploadActions.UPLOAD_DONE:
            return {
                ...state,
                isUploading: false,
            };
        case FileUploadActions.CLEAR_UPLOADED_LIST:
            return {
                ...state,
                uploadedFiles: [],
            };
        case FileUploadActions.REMOVE_FILE_FROM_UPLOADED_LIST:
            return {
                ...state,
                uploadedFiles: [
                    ...state.uploadedFiles.filter(
                        (upload) => upload.key !== action.payload.file.key
                    ),
                ],
            };
        // This is a convenience action because Dropzone already provides us the rejection in the correct format
        case FileUploadActions.ADD_FILES_TO_REJECTED_LIST:
            return {
                ...state,
                rejectedFiles: [
                    ...transformFileRejectionToRejectedFile(action.payload.rejections),
                    ...state.rejectedFiles,
                ],
            };
        // This is needed when we just have a File and need to reject it
        case FileUploadActions.UPLOAD_FAILURE:
            return {
                ...state,
                rejectedFiles: [
                    ...transformUploadFailureToRejectedFile(action.payload),
                    ...state.rejectedFiles,
                ],
            };
        case FileUploadActions.REMOVE_FILE_FROM_REJECTED_LIST:
            return {
                ...state,
                rejectedFiles: [
                    ...state.rejectedFiles.filter(
                        (rejection) => rejection.key !== action.payload.file.key
                    ),
                ],
            };
        case FileUploadActions.CLEAR_REJECTED_LIST:
            return {
                ...state,
                rejectedFiles: [],
            };
        default:
            throw Error('unknown action');
    }
}

// TODO: create action creators that can be passed to the child instead of exporting an enum
// @src https://stackoverflow.com/questions/65609978/how-to-properly-pass-usereducer-actions-down-to-children-without-causing-unneces

export interface FileUploadProps
    extends Pick<
        FileDropAreaProps,
        | 'acceptFileTypes'
        | 'fileValidator'
        | 'iconFor'
        | 'primaryLabel'
        | 'secondaryLabel'
        | 'disabled'
        | 'maxFiles'
        | 'maxSize'
        | 'multiple'
        | 'showProgress'
    > {
    /** Function that handles the actual uploading */
    handleUpload: ({
        acceptedFiles,
        dispatch,
    }: {
        acceptedFiles: File[];
        dispatch: React.Dispatch<Action>;
    }) => void;
}

export default function FileUpload({
    acceptFileTypes,
    disabled,
    fileValidator,
    handleUpload,
    iconFor = 'file',
    maxFiles,
    maxSize,
    multiple = true,
    primaryLabel,
    secondaryLabel,
    showProgress = false,
}: FileUploadProps) {
    const [{ errors, isUploading, percentComplete, rejectedFiles, uploadedFiles }, dispatch] =
        useReducer(reducer, {
            isUploading: false,
            percentComplete: 0,
            uploadedFiles: [],
            rejectedFiles: [],
        });

    const { errorId, isInvalid } = checkForInputErrors({ errors, name: INPUT_NAME });

    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            if (fileRejections.length > 0) {
                dispatch({
                    type: FileUploadActions.ADD_FILES_TO_REJECTED_LIST,
                    payload: { rejections: fileRejections },
                });
            }

            if (acceptedFiles.length > 0) {
                handleUpload({ acceptedFiles, dispatch });
            }
        },
        [handleUpload]
    );

    return (
        <>
            <FileDropArea
                acceptFileTypes={acceptFileTypes}
                aria-describedby={errorId}
                disabled={disabled}
                fileValidator={fileValidator}
                iconFor={iconFor}
                isInvalid={isInvalid}
                isUploading={isUploading}
                maxFiles={maxFiles}
                maxSize={maxSize}
                multiple={multiple}
                onDrop={onDrop}
                percentComplete={percentComplete}
                primaryLabel={primaryLabel}
                secondaryLabel={secondaryLabel}
                showProgress={showProgress}
            />

            {errors && (
                <div className={classes['network-error']}>
                    <ErrorMessage errors={errors} id={errorId} name={INPUT_NAME} />
                </div>
            )}

            {uploadedFiles.length > 0 && (
                <UploadedList
                    files={uploadedFiles}
                    onClear={() => dispatch({ type: FileUploadActions.CLEAR_UPLOADED_LIST })}
                    onRemoveFile={(file) => {
                        dispatch({
                            type: FileUploadActions.REMOVE_FILE_FROM_UPLOADED_LIST,
                            payload: { file },
                        });
                    }}
                />
            )}

            {rejectedFiles.length > 0 && (
                <RejectedList
                    files={rejectedFiles}
                    onClear={() => dispatch({ type: FileUploadActions.CLEAR_REJECTED_LIST })}
                    onRemoveFile={(file) => {
                        dispatch({
                            type: FileUploadActions.REMOVE_FILE_FROM_REJECTED_LIST,
                            payload: { file },
                        });
                    }}
                />
            )}
        </>
    );
}
