import { LegacyRef, MutableRefObject, RefCallback } from 'react';

/**
 * Allows you to use multiple refs on a single component/element. Basic use
 * case is if you need to support forwardRef on a component but also need
 * to have a local ref for some logic in the same component.
 *
 * @src https://github.com/gregberge/react-merge-refs/blob/main/src/index.tsx
 */
export default function mergeRefs<T = any>(
    refs: Array<MutableRefObject<T> | LegacyRef<T>>
): RefCallback<T> {
    return (value) => {
        refs.forEach((ref) => {
            if (typeof ref === 'function') {
                ref(value);
            } else if (ref != null) {
                (ref as MutableRefObject<T | null>).current = value;
            }
        });
    };
}
