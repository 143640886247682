// import { useGlobalAuth } from '@keyliving/component-lib';
import { Outlet } from 'react-router-dom';

// import { useAuth } from '../hooks';
// import AuthLayout from './layout/AuthLayout';

export default function PrivateRoute() {
    // const { isLoggedIn } = useAuth();
    // const { redirectToAuth } = useGlobalAuth();

    // if (!isLoggedIn) {
    //     /**
    //      * Redirect them to the /login page, but save the current page they were
    //      * trying to access when they were redirected.
    //      */
    //     redirectToAuth(window.location.href);
    //     return null;
    // }

    return <Outlet />;
}
