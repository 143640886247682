import classNames from 'classnames/bind';
import type { AriaAttributes, MouseEventHandler, ReactElement, ReactNode } from 'react';
import { cloneElement, forwardRef, useMemo } from 'react';

import type { IconProps } from '../../icons';
import { resolveResponsiveClasses } from '../../lib/cssClassResolver';
import type { ResponsiveProp } from '../../types';
import LoadingRing from '../LoadingRing';
import classes from './Button.module.scss';

const cx = classNames.bind(classes);

export type BtnSize = 'xs' | 'sm' | 'md' | 'lg';
export type BtnWidth = 'auto' | 'full';

export interface CommonBtnProps extends AriaAttributes {
    /** A valid ReactNode */
    children?: ReactNode;
    /** Add additional css classes */
    className?: string;
    /** Is the button in a disabled state */
    disabled?: boolean;
    /** A valid theme */
    fill?: 'solid' | 'outline' | 'ghost' | 'transparent' | null;
    /** An icon to be added inline before the text */
    icon?: ReactElement<IconProps>;
    /** Unique ID */
    id?: string;
    /** Is the button in a loading state */
    isLoading?: boolean;
    /** If the button should be rounded */
    rounded?: boolean;
    /** A static button size or a responsive object */
    size?: ResponsiveProp<BtnSize>;
    /** A valid theme */
    theme?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'muted' | 'destructive' | null;
    /** Width of the button */
    width?: ResponsiveProp<BtnWidth>;
}

export interface ButtonTypeProps extends CommonBtnProps {
    /** Function to handle onClick event */
    onClick: MouseEventHandler<HTMLButtonElement>;
    type?: 'button';
}

export interface SubmitButtonTypeProps extends CommonBtnProps {
    onClick?: never;
    type: 'submit';
}

export type ButtonProps = ButtonTypeProps | SubmitButtonTypeProps;

const indicatorSizeMap: Record<BtnSize, number> = {
    xs: 22,
    sm: 28,
    md: 36,
    lg: 40,
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            className,
            disabled = false,
            fill = 'solid',
            icon,
            isLoading,
            onClick,
            rounded = false,
            size = 'sm',
            theme = 'primary',
            type = 'button',
            width = 'auto',
            ...rest
        },
        ref
    ) => {
        const isDisabled = isLoading ? true : disabled;

        const sizeClasses = useMemo(() => {
            return resolveResponsiveClasses<BtnSize>({ propValue: size, baseClass: 'btn' });
        }, [size]);

        const widthClasses = useMemo(() => {
            return resolveResponsiveClasses<BtnWidth>({ propValue: width, baseClass: 'btn' });
        }, [width]);

        const indicatorSize = useMemo<number>(() => {
            if (typeof size === 'string') {
                return indicatorSizeMap[size];
            }

            if (size === null) {
                return indicatorSizeMap['xs'];
            }

            /**
             * If a responsive object, get the smallest size
             *
             * Lucky for us the button sizes are in reverse alphabetical
             * order. If this changes, we will need some sort of custom
             * sort function.
             */
            const sizes = Object.values(size).sort().reverse();

            return indicatorSizeMap[sizes[0]];
        }, [size]);

        return (
            <button
                className={cx(
                    'btn',
                    {
                        [`btn--${theme}`]: !!theme,
                        [`btn--${fill}`]: !!fill,
                        'btn--rounded': rounded,
                    },
                    widthClasses,
                    sizeClasses,
                    className
                )}
                disabled={isDisabled}
                onClick={onClick}
                ref={ref}
                type={type}
                {...rest}
            >
                {icon ? (
                    <span className={classes.btn__icon} data-testid="btn-icon">
                        {cloneElement(icon, {
                            width: undefined,
                            height: undefined,
                            'aria-hidden': true,
                        })}
                    </span>
                ) : null}
                {children}
                {isLoading ? (
                    <span className={classes['loading-indicator']}>
                        <LoadingRing width={indicatorSize} />
                    </span>
                ) : null}
            </button>
        );
    }
);

export default Button;
