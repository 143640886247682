import classNames from 'classnames/bind';
import { forwardRef, PropsWithChildren } from 'react';

import classes from './InputWrapper.module.scss';

const cx = classNames.bind(classes);

interface WrapperProps {
    className?: string;
    disabled?: boolean;
    invalid?: boolean;
}

export type InputWrapperProps = PropsWithChildren<WrapperProps>;

const InputWrapper = forwardRef<HTMLDivElement, InputWrapperProps>(
    ({ children, className, disabled, invalid }, ref) => {
        return (
            <div
                className={cx(
                    classes['input-wrapper'],
                    { disabled, 'input-wrapper--invalid': invalid },
                    className
                )}
                data-testid="input-wrapper"
                ref={ref}
            >
                {children}
            </div>
        );
    }
);

export default InputWrapper;
