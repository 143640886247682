import classNames from 'classnames/bind';
import { Fragment } from 'react';
import {
    ClearIndicatorProps,
    components,
    ControlProps,
    DropdownIndicatorProps,
    GroupBase,
    IndicatorSeparatorProps,
    MultiValueGenericProps,
    MultiValueRemoveProps,
    OptionProps,
    PlaceholderProps,
    SingleValueProps,
    ValueContainerProps,
} from 'react-select';

import { ChevronDown, CloseIcon } from '../../icons';
import highlightFoundTermInString from '../../lib/highlightFoundString';
import InputWrapper from '../InputWrapper';
import { SearchableSelectOption } from './SearchableSelect';
import classes from './SearchableSelect.module.scss';

const cx = classNames.bind(classes);

export const Control = (
    props: ControlProps<SearchableSelectOption, boolean, GroupBase<SearchableSelectOption>>
) => {
    const { isDisabled, selectProps } = props;
    const { isInvalid } = selectProps;

    return (
        <InputWrapper disabled={isDisabled} invalid={isInvalid}>
            <components.Control
                {...props}
                className={cx(classes.select__control, {
                    'select__control--disabled': isDisabled,
                })}
            />
        </InputWrapper>
    );
};

export const DropdownIndicator = (
    props: DropdownIndicatorProps<
        SearchableSelectOption,
        boolean,
        GroupBase<SearchableSelectOption>
    >
) => {
    const { innerProps } = props;

    return (
        <div {...innerProps} className={classes.select__dropdown}>
            <ChevronDown height={8} width={15} />
        </div>
    );
};

export const ClearIndicator = (
    props: ClearIndicatorProps<SearchableSelectOption, boolean, GroupBase<SearchableSelectOption>>
) => {
    const { innerProps } = props;

    return (
        <div {...innerProps} className={classes.select__clear}>
            <CloseIcon height={12} width={12} />
        </div>
    );
};

export const IndicatorSeparator = ({
    hasValue,
    innerProps,
    isDisabled,
    selectProps,
}: IndicatorSeparatorProps<SearchableSelectOption, boolean, GroupBase<SearchableSelectOption>>) => {
    const { isClearable } = selectProps;

    if (isDisabled || !hasValue || !isClearable) {
        return null;
    }

    return <span {...innerProps} className={classes.select__separator} />;
};

export const Placeholder = (
    props: PlaceholderProps<SearchableSelectOption, boolean, GroupBase<SearchableSelectOption>>
) => {
    return <components.Placeholder {...props} className={classes.select__placeholder} />;
};

export const SingleValue = ({
    children,
    ...props
}: SingleValueProps<SearchableSelectOption, boolean, GroupBase<SearchableSelectOption>>) => (
    <components.SingleValue {...props} className={classes.select__single}>
        {children}
    </components.SingleValue>
);

// Container responsible for loading the placeholder value and the input
export const ValueContainer = (
    props: ValueContainerProps<SearchableSelectOption, boolean, GroupBase<SearchableSelectOption>>
) => {
    const { children } = props;
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
};

export const MultiValueContainer = (
    props: MultiValueGenericProps<
        SearchableSelectOption,
        boolean,
        GroupBase<SearchableSelectOption>
    >
) => {
    const { children, innerProps } = props;
    return (
        <div {...innerProps} className={classes.select__chip}>
            {children}
        </div>
    );
};

export const MultiValueLabel = (
    props: MultiValueGenericProps<
        SearchableSelectOption,
        boolean,
        GroupBase<SearchableSelectOption>
    >
) => {
    const { children, innerProps } = props;
    return (
        <div {...innerProps} className={classes['select__chip-label']}>
            {children}
        </div>
    );
};

export const MultiValueRemove = (
    props: MultiValueRemoveProps<SearchableSelectOption, boolean, GroupBase<SearchableSelectOption>>
) => {
    const { innerProps } = props;

    return (
        <div {...innerProps} className={classes['select__chip-action']}>
            <CloseIcon height={12} width={12} />
        </div>
    );
};

export const Option = (
    props: OptionProps<SearchableSelectOption, boolean, GroupBase<SearchableSelectOption>>
) => {
    const {
        data: { label },
        innerProps,
        isSelected,
        selectProps: { inputValue },
    } = props;

    // If in search mode, highlight the found search term in each option
    const formattedLabel = highlightFoundTermInString(inputValue, label, classes.highlight);

    return (
        <div
            {...innerProps}
            className={cx(classes.select__option, {
                'select__option--selected': isSelected,
            })}
        >
            {formattedLabel.map((label, index) => (
                <Fragment key={index}>{label}</Fragment>
            ))}
        </div>
    );
};

export const customComponents = {
    Control,
    Placeholder,
    DropdownIndicator,
    ClearIndicator,
    IndicatorSeparator,
    ValueContainer,
    SingleValue,
    MultiValueLabel,
    MultiValueRemove,
    MultiValueContainer,
    Option,
};
