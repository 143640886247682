import classnames from 'classnames/bind';

import classes from './ErrorMessage.module.scss';
import { checkForInputErrors } from './lib';
import { ValidationErrors } from './types';

const cx = classnames.bind(classes);

export interface ErrorMessageProps {
    /** Custom css className */
    className?: string;
    /** Error object exported from `useForm` */
    errors?: ValidationErrors;
    /** id of the input that the error is describing */
    id?: string;
    /** Custom error message if not using `useForm` */
    message?: string;
    /** Name of the form input that's used to get the error msg off the errors object */
    name: string;
}

export default function ErrorMessage({ className, errors, id, message, name }: ErrorMessageProps) {
    const { error, errorId } = checkForInputErrors({ errors, name });
    const msg = message ?? error?.message;

    if (!msg) {
        return null;
    }

    return (
        <div className={cx('error-msg', className)} id={id ?? errorId}>
            <span className={cx('sr-only')}>Error:</span> {msg}
        </div>
    );
}
