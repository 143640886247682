import classNames from 'classnames';
import { forwardRef, InputHTMLAttributes } from 'react';

import classes from './RadioButtonTagGroup.module.scss';

const cx = classNames.bind(classes);

export interface RadioTagProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
}

const RadioTag = forwardRef<HTMLInputElement, RadioTagProps>(
    (
        {
            className,
            defaultChecked,
            disabled,
            label,
            name,
            onBlur,
            onChange,
            required,
            value,
            ...rest
        },
        ref
    ) => {
        return (
            <div className={classes.tag}>
                <label>
                    <input
                        defaultChecked={defaultChecked}
                        disabled={disabled}
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        ref={ref}
                        required={required}
                        type="radio"
                        value={value}
                        {...rest}
                    />
                    <span className={cx(classes.tag__label, className)}>{label}</span>
                </label>
            </div>
        );
    }
);

export default RadioTag;
