import classNames from 'classnames/bind';
import { ChangeEventHandler, FocusEventHandler, forwardRef } from 'react';

import classes from './RadioButtonBar.module.scss';

const cx = classNames.bind(classes);

export interface Option {
    label: string;
    value: number | string;
}

export interface RadioButtonBarProps {
    /** Current value that should display as selected */
    defaultCheckedValue?: number | string;
    /** Is the button bar in a disabled state */
    disabled?: boolean;
    /** Name attribute for the input */
    name: string;
    /** onBlur event handler */
    onBlur?: FocusEventHandler<HTMLInputElement>;
    /** onChange event handler */
    onChange?: ChangeEventHandler<HTMLInputElement>;
    /** Selectable options */
    options: Option[];
    /** Is the button bar required */
    required?: boolean;
}

const RadioButtonBar = forwardRef<HTMLInputElement, RadioButtonBarProps>(
    ({ defaultCheckedValue, disabled, name, onBlur, onChange, options, required }, ref) => {
        return (
            <div className={cx('wrapper')}>
                {options.map((option) => {
                    const { label, value: optionValue } = option;

                    return (
                        <label className={cx('option')} key={optionValue}>
                            <input
                                defaultChecked={optionValue === defaultCheckedValue}
                                disabled={disabled}
                                name={name}
                                onBlur={onBlur}
                                onChange={onChange}
                                ref={ref}
                                required={required}
                                type="radio"
                                value={optionValue}
                            />
                            <span className={cx('option__label')}>{label}</span>
                        </label>
                    );
                })}
            </div>
        );
    }
);

export default RadioButtonBar;
