import { IconProps } from './types';

export default function ShowIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <g>
                <path
                    d="M23.43 10.52c-2.64-2.91-7-6-11.43-5.92S3.21 7.61.57 10.52a2.22 2.22 0 0 0 0 3C2.33 15.42 6.74 19.4 12 19.4s9.66-4 11.43-5.93a2.22 2.22 0 0 0 0-2.95ZM7.4 12a4.6 4.6 0 1 1 4.6 4.6A4.6 4.6 0 0 1 7.4 12Z"
                    fill="currentColor"
                />
                <circle cx="12" cy="12" fill="currentColor" r="2" />
            </g>
        </svg>
    );
}
