import { createContext, ReactNode, useState } from 'react';

export interface TabsContextData {
    activeTab: string;
    setActiveTab: (id: string) => void;
}

interface ProviderProps {
    children: ReactNode;
    defaultTab: string;
    onChangeTab?: (newTab: string) => void;
}

const defaultState: TabsContextData = {
    activeTab: '',
    setActiveTab: () => {},
};

const TabsContext = createContext<TabsContextData>(defaultState);

export function TabsProvider({ children, defaultTab, onChangeTab }: ProviderProps) {
    const [activeTab, setActiveTab] = useState<string>(defaultTab);

    const handleChangeTab = (tabId: string) => {
        setActiveTab(tabId);

        if (onChangeTab) {
            onChangeTab(tabId);
        }
    };

    return (
        <TabsContext.Provider value={{ activeTab, setActiveTab: handleChangeTab }}>
            {children}
        </TabsContext.Provider>
    );
}

export default TabsContext;
