/**
 * Inspiration for these heavily taken from - https://javascript.info/cookie
 *
 * A note on GDPR:
 * If we set a cookie that just saves some information, but neither tracks nor identifies the user,
 * then we are free to do it. But if we are going to set a cookie with an authentication session
 * or a tracking id, then a user must allow that.
 */

/**
 * Returns the cookie with the given name, or undefined if not found
 */
export function getCookie(name: string): string | undefined {
    const matches = document.cookie.match(
        // eslint-disable-next-line no-useless-escape
        new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export interface CookieOptions {
    domain?: string;
    expires?: Date | string;
    path?: string;
    samesite?: 'strict' | 'lax';
    secure?: Boolean;
}

export function setCookie(name: string, value: string, options: CookieOptions = {}): void {
    const cookieOptions: CookieOptions = {
        path: '/',
        samesite: 'strict',
        secure: true,
        // add other defaults here if necessary
        ...options,
    };

    if (cookieOptions.expires instanceof Date) {
        cookieOptions.expires = cookieOptions.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (const optionKey in cookieOptions) {
        updatedCookie += '; ' + optionKey;
        const optionValue = cookieOptions[optionKey as keyof CookieOptions];

        if (optionValue !== true) {
            updatedCookie += '=' + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

/**
 * To delete a cookie, we can update it with a date in the past
 *
 * Note: when we update or delete a cookie, we should use
 * exactly the same path and domain options as when we
 * set it.
 */
export function deleteCookie(name: string, options: CookieOptions = {}): void {
    setCookie(name, '', {
        ...options,
        expires: 'Thu, 01 Jan 1970 00:00:00 UTC',
    });
}
