import classNames from 'classnames/bind';
import { HTMLProps, PropsWithChildren } from 'react';

import classes from './Anchor.module.scss';

const cx = classNames.bind(classes);

export type AnchorProps = HTMLProps<HTMLAnchorElement> & {
    /** Will force the `display: block` css property */
    block?: boolean;
    /** Force download of the supplied file */
    download?: boolean;
};

const Anchor = ({
    block = false,
    children,
    className,
    download,
    href,
    ...rest
}: PropsWithChildren<AnchorProps>) => {
    return (
        <a download={download} href={href} {...rest} className={cx(className, { block })}>
            {children}
        </a>
    );
};

export default Anchor;
