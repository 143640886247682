import { createContext, ReactNode, useCallback, useReducer } from 'react';

export interface SideMenuContextData {
    menuExpanded: boolean;
    menuOpen: boolean;
    toggleMenuExpanded: () => void;
    toggleMenuFully: () => void;
    toggleMenuOpen: () => void;
}

export interface ProviderProps {
    /** A valid ReactNode */
    children?: ReactNode;
    /** Should the menu display by default (unless the user specifies otherwise) */
    defaultMenuOpen?: boolean;
}

const defaultState: SideMenuContextData = {
    menuOpen: false,
    toggleMenuOpen: () => {},
    menuExpanded: false,
    toggleMenuExpanded: () => {},
    toggleMenuFully: () => {},
};

const SideMenuContext = createContext<SideMenuContextData>(defaultState);

export type State = {
    menuExpanded: boolean;
    menuOpen: boolean;
};

export enum Actions {
    SET_MENU_OPEN,
    SET_MENU_EXPANDED,
    SET_MENU_FULLY,
}

export type Action =
    | { payload: { menuOpen: boolean }; type: Actions.SET_MENU_OPEN }
    | { payload: { menuExpanded: boolean }; type: Actions.SET_MENU_EXPANDED }
    | { payload: { menuExpanded: boolean; menuOpen: boolean }; type: Actions.SET_MENU_FULLY };

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case Actions.SET_MENU_OPEN:
            return {
                ...state,
                menuOpen: action.payload.menuOpen,
            };
        case Actions.SET_MENU_EXPANDED:
            return {
                ...state,
                menuExpanded: action.payload.menuExpanded,
            };
        case Actions.SET_MENU_FULLY:
            return {
                ...state,
                menuOpen: action.payload.menuOpen,
                menuExpanded: action.payload.menuExpanded,
            };
        default:
            throw Error('unknown action');
    }
}

export function SideMenuProvider({ children, defaultMenuOpen = false }: ProviderProps) {
    const [{ menuExpanded, menuOpen }, dispatch] = useReducer(
        reducer,
        { menuOpen: defaultMenuOpen },
        (params) => {
            const savedOpenState = localStorage.getItem('menuOpen');
            const menuIsOpen = savedOpenState === 'true';

            const savedExpandedState = localStorage.getItem('menuExpanded');

            return {
                menuOpen: savedOpenState === null ? params.menuOpen : menuIsOpen,
                menuExpanded: savedExpandedState === 'true',
            };
        }
    );

    const toggleMenuOpen = useCallback(() => {
        const nextMenuIsOpen = !menuOpen;
        localStorage.setItem('menuOpen', String(nextMenuIsOpen));

        // If we are closing the menu, collapse it too
        if (!nextMenuIsOpen) {
            dispatch({
                type: Actions.SET_MENU_FULLY,
                payload: {
                    menuOpen: false,
                    menuExpanded: false,
                },
            });

            localStorage.setItem('menuExpanded', String(false));
        } else {
            dispatch({
                type: Actions.SET_MENU_OPEN,
                payload: {
                    menuOpen: true,
                },
            });
        }
    }, [menuOpen]);

    const toggleMenuExpanded = useCallback(() => {
        const nextMenuIsExpanded = !menuExpanded;
        localStorage.setItem('menuExpanded', String(nextMenuIsExpanded));

        dispatch({
            type: Actions.SET_MENU_EXPANDED,
            payload: {
                menuExpanded: nextMenuIsExpanded,
            },
        });
    }, [menuExpanded]);

    const toggleMenuFully = useCallback(() => {
        const nextMenuIsOpen = !menuOpen;
        localStorage.setItem('menuOpen', String(nextMenuIsOpen));
        localStorage.setItem('menuExpanded', String(nextMenuIsOpen));

        dispatch({
            type: Actions.SET_MENU_FULLY,
            payload: {
                menuOpen: nextMenuIsOpen,
                menuExpanded: nextMenuIsOpen,
            },
        });
    }, [menuOpen]);

    return (
        <SideMenuContext.Provider
            value={{ menuOpen, toggleMenuOpen, menuExpanded, toggleMenuExpanded, toggleMenuFully }}
        >
            {children}
        </SideMenuContext.Provider>
    );
}

export default SideMenuContext;
