import { usePagination } from '../../hooks';
import classes from './Pagination.module.scss';
import PaginationItem from './PaginationItem';

export interface PaginationProps {
    /** Aria Label for `nav` container */
    'aria-label'?: string;
    /** Current page number */
    currentPage: number;
    /** Function passed number clicked */
    onGoTo: (page: number) => void;
    /** Function called when next clicked */
    onNext: () => void;
    /** Function called when prev clicked */
    onPrev: () => void;
    /** Total number of pages */
    totalNumPages: number;
}

export default function Pagination({
    'aria-label': ariaLabel = 'Pagination',
    currentPage,
    onGoTo,
    onNext,
    onPrev,
    totalNumPages,
}: PaginationProps) {
    const navItems = usePagination({
        totalNumPages,
        currentPage,
        siblingCount: 1,
    });

    return (
        <nav aria-label={ariaLabel} className={classes.wrapper} role="navigation">
            <PaginationItem
                aria-label="Go To Previous Page"
                disabled={currentPage === 1}
                key="prev"
                label="Prev"
                onClick={() => onPrev()}
            />

            {navItems.map((item, index) => {
                if (item === null) {
                    return (
                        <div className={classes.ellipsis} key={`${item}-${index}`}>
                            ...
                        </div>
                    );
                }

                return (
                    <PaginationItem
                        aria-label={`Go To Page ${item}`}
                        isCurrentPage={currentPage === item}
                        key={item}
                        label={`${item}`}
                        onClick={() => onGoTo(item)}
                    />
                );
            })}

            <PaginationItem
                aria-label="Go To Next Page"
                disabled={currentPage === totalNumPages}
                key="next"
                label="Next"
                onClick={() => onNext()}
            />
        </nav>
    );
}
