import classes from './ProgressRing.module.scss';

export interface ProgressRingProps {
    /** Percentage of the circle that should be drawn */
    percentage?: number;
    /** px value of the stroke width */
    strokeWidth?: number;
    /** Width in px of the ring */
    width?: number;
}

export default function ProgressRing({
    percentage = 0,
    strokeWidth = 6,
    width = 100,
}: ProgressRingProps) {
    const radius = width / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = ((100 - percentage) / 100) * circumference;

    return (
        <svg
            aria-valuenow={percentage}
            className={classes.svg}
            height={width}
            preserveAspectRatio="xMinYMin meet"
            role="progressbar"
            viewBox={`0 0 ${width} ${width}`}
            width={width}
        >
            <circle
                className={classes.ring}
                cx="50%"
                cy="50%"
                r={radius}
                strokeWidth={`${strokeWidth}px`}
            />
            <circle
                className={classes.progress}
                cx="50%"
                cy="50%"
                r={radius}
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                strokeWidth={`${strokeWidth}px`}
            />
            <text
                className={classes.text}
                dominantBaseline="central"
                textAnchor="middle"
                x="50%"
                y="50%"
            >
                {percentage}%
            </text>
        </svg>
    );
}
