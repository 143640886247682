import classNames from 'classnames/bind';
import { ReactNode, useState } from 'react';

import { FilterIcon } from '../../../../../icons';
import Button from '../../../../Button';
import { ChildrenProps } from '../../../ConfigurableTable';
import GlobalSearch from '../GlobalSearch';
import classes from './FilterToolbar.module.scss';

const cx = classNames.bind(classes);

interface FilterToolbarProps<T extends object = {}> extends ChildrenProps<T> {
    children?: ReactNode;
}

export default function FilterToolbar<T extends object = {}>(props: FilterToolbarProps<T>) {
    const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

    const {
        children,
        currentColumnFilters,
        currentGlobalFilterValue,
        setAllColumnFilters,
        setColumnFilter,
        setGlobalFilterValue,
    } = props;

    return (
        <div>
            <div className={cx('actions')}>
                <div className={cx('actions__flex')}>
                    <GlobalSearch
                        currentGlobalFilterValue={currentGlobalFilterValue}
                        setGlobalFilterValue={setGlobalFilterValue}
                    />
                </div>

                <div>
                    <Button
                        fill="outline"
                        icon={<FilterIcon />}
                        onClick={() => {
                            setFiltersOpen(!filtersOpen);
                        }}
                        size="md"
                        theme="secondary"
                        type="button"
                    >
                        Filters
                        {currentColumnFilters.length > 0 ? (
                            <span>&nbsp;({currentColumnFilters.length})</span>
                        ) : null}
                    </Button>
                </div>
            </div>

            <div className={cx('filter-wrapper')}>
                <div
                    className={cx('filter-drawer', {
                        'filter-drawer--open': filtersOpen,
                    })}
                >
                    <div className={cx('filter-drawer__contents')}>
                        <div className={cx('filters')}>{children ? children : null}</div>
                    </div>
                </div>

                {currentColumnFilters.length > 0 ? (
                    <div className={cx('current-filters')}>
                        {currentColumnFilters.map(({ id, value }) => {
                            // Ensure we have an array for our join later
                            const displayValue = Array.isArray(value) ? value : [value];

                            return (
                                <Button
                                    fill="outline"
                                    key={id}
                                    onClick={() => {
                                        setColumnFilter(id, undefined);
                                    }}
                                    rounded
                                    theme="secondary"
                                    type="button"
                                >
                                    Clear: {id} - {displayValue.join(', ')}
                                </Button>
                            );
                        })}
                        {currentColumnFilters.length > 1 ? (
                            <Button
                                fill="transparent"
                                onClick={() => {
                                    setAllColumnFilters([]);
                                }}
                                theme="tertiary"
                                type="button"
                            >
                                Clear All
                            </Button>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    );
}
