import { Breakpoints, ResponsiveProp } from '../types';

interface FunctionParams<T> {
    baseClass: string;
    propValue: ResponsiveProp<T>;
}

/**
 * Resolves the class string for the button size(s) wether given
 * a string or responsive prop object for size.
 *
 * Generic of T should be valid values for prop in question.
 *
 *  eg. size="sm" -> .btn--sm
 *  eg. size={ md: 'lg' } -> .md:btn--lg
 *  eg. size={ xs: 'md', md: 'lg' } -> .btn--md .md:btn--lg
 */
export function resolveResponsiveClasses<T>({ baseClass, propValue }: FunctionParams<T>) {
    if (propValue === null) {
        return null;
    }

    if (typeof propValue === 'string') {
        return [`${baseClass}--${propValue}`];
    }

    // If given a responsive prop object
    return (Object.entries(propValue) as [Breakpoints, T][]).map((item) => {
        const [breakpoint, propValue] = item;

        if (breakpoint === 'xs') {
            return `${baseClass}--${propValue}`;
        }

        return `${breakpoint}:${baseClass}--${propValue}`;
    });
}
