import classNames from 'classnames/bind';
import { MouseEventHandler } from 'react';

import classes from './Pagination.module.scss';

const cx = classNames.bind(classes);

interface PaginationItemProps {
    /** Descriptive label for screen readers */
    'aria-label': string;
    /** Is the button disabled */
    disabled?: boolean;
    /** Is the current page */
    isCurrentPage?: boolean;
    /** Visible label */
    label: string;
    /** Click event handler */
    onClick: MouseEventHandler<HTMLButtonElement>;
}

export default function PaginationItem({
    'aria-label': ariaLabel,
    disabled,
    isCurrentPage,
    label,
    onClick,
}: PaginationItemProps) {
    return (
        <button
            aria-current={isCurrentPage ? 'page' : undefined}
            aria-label={ariaLabel}
            className={cx('nav-item', {
                'nav-item--active': isCurrentPage,
            })}
            disabled={disabled}
            onClick={onClick}
            type="button"
        >
            {label}
        </button>
    );
}
