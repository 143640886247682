import { User } from '@keyliving/shared-types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { AuthState } from './types';

export const initialAuthState: AuthState = { user: null, claims: null, token: null };

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        setAuthDetails: (state, action: PayloadAction<AuthState>) => {
            const { claims, token, user } = action.payload;
            Object.assign(state, { user, claims, token });
        },
        clearAuthDetails: () => {
            return initialAuthState;
        },
        setCurrentUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
        updateUserDetails: (
            state,
            action: PayloadAction<Partial<Pick<User, 'preferred_name' | 'email' | 'phone_number'>>>
        ) => {
            const user = state.user;

            if (user) {
                Object.assign(user, { ...action.payload });
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const { clearAuthDetails, setAuthDetails, setCurrentUser, updateUserDetails } =
    authSlice.actions;

export default authSlice.reducer;
