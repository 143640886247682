import classNames from 'classnames/bind';
import { cloneElement, ReactElement } from 'react';

import { TabProps } from './Tab';
import classes from './Tabs.module.scss';
import { TabsProvider } from './TabsContext';
import useTabs from './useTabs';

const cx = classNames.bind(classes);

export interface TabsProps {
    /** Aria label for the tab list */
    'aria-label'?: string;
    /** Tab components */
    children: ReactElement<TabProps> | ReactElement<TabProps>[];
    /** Additional CSS classes */
    className?: string;
    /** tabId of the tab you want selected by default. Otherwise the first one will be selected. */
    defaultTab?: string;
    /** Callback when tab changes */
    onChangeTab?: (newTab: string) => void;
}

function WrappedTabs({ 'aria-label': ariaLabel = 'Tabs', children, className }: TabsProps) {
    const { activeTab, setActiveTab } = useTabs();
    const tabs = Array.isArray(children) ? children : [children];

    return (
        <>
            <div aria-label={ariaLabel} className={cx('tab-nav', className)} role="tablist">
                {tabs.map((tab) => {
                    const { className, label, tabId } = tab.props;
                    const isActive = tabId === activeTab;
                    const classes =
                        typeof className === 'function' ? className({ isActive }) : className;

                    return (
                        <button
                            aria-controls={`tab-panel-${tabId}`}
                            aria-selected={isActive}
                            className={cx(
                                'tab',
                                {
                                    'tab--active': isActive,
                                },
                                classes
                            )}
                            id={`tab-nav-${tabId}`}
                            key={tabId}
                            onClick={() => {
                                setActiveTab(tabId);
                            }}
                            role="tab"
                            tabIndex={isActive ? 0 : -1}
                        >
                            {label}
                        </button>
                    );
                })}
            </div>
            {tabs.map((tab) => {
                const { tabId } = tab.props;

                if (tabId !== activeTab) return null;

                return cloneElement(tab, {
                    'aria-labelledby': `tab-nav-${tabId}`,
                    id: `tab-panel-${tabId}`,
                    key: tabId,
                });
            })}
        </>
    );
}

export default function Tabs({ children, defaultTab, onChangeTab, ...rest }: TabsProps) {
    const firstChild = Array.isArray(children) ? children[0] : children;
    const defaultActiveTab = defaultTab ?? firstChild.props.tabId;

    return (
        <TabsProvider defaultTab={defaultActiveTab} onChangeTab={onChangeTab}>
            <WrappedTabs {...rest}>{children}</WrappedTabs>
        </TabsProvider>
    );
}
