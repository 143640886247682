import { forwardRef } from 'react';

import BaseInput, { BaseInputProps } from '../BaseInput';

export const dateInputRegex = /\d{4}-\d{2}-\d{2}/;

export type DateInputProps = BaseInputProps;

const DateInput = forwardRef<HTMLInputElement, DateInputProps>((props, ref) => {
    /** NOTE: Usage of the pattern attribute is to try and enforce
     * the expected date pattern in browsers that don't support
     * type="date" and fallback to type="text". Validation
     * should still be done with a JS validation lib.
     *
     * @src https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#handling_browser_support
     */
    return <BaseInput {...props} pattern="\d{4}-\d{2}-\d{2}" ref={ref} type="date" />;
});

export default DateInput;
