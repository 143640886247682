import {
    CookieOptions,
    deleteCookie as removeCookie,
    getCookie,
    setCookie as upsertCookie,
} from '@keyliving/utils';

export default function useCookie(name: string, defaultOptions?: CookieOptions) {
    const value = getCookie(name);

    /**
     * setCookie and deleteCookie must use the same path and domain so for convenience, instead
     * of requiring the user to pass them as defaultOptions ever time they want to use the
     * hook, let's set some defaults here. But remember that if you pass a custom path or
     * domain, you have to pass the same custom path and domain everytime you use the
     * hook for the same cookie.
     */
    const cookieOptions: CookieOptions = {
        path: '/',
        domain: window.location.hostname,
        ...defaultOptions,
    };

    const setCookie = (value: string, options?: CookieOptions) => {
        upsertCookie(name, value, {
            ...cookieOptions,
            ...options,
        });
    };

    const deleteCookie = (options?: Omit<CookieOptions, 'expires'>) => {
        removeCookie(name, {
            ...cookieOptions,
            ...options,
        });
    };

    return {
        value,
        setCookie,
        deleteCookie,
    };
}
