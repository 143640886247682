import classNames from 'classnames/bind';
import { useMemo } from 'react';

import { resolveResponsiveClasses } from '../../lib/cssClassResolver';
import { ResponsiveProp } from '../../types';
import classes from './Avatar.module.scss';
import { getInitials } from './lib';

const cx = classNames.bind(classes);

export type AvatarSize = 'sm' | 'md' | 'lg';

export interface AvatarProps {
    /** Add additional css classes */
    className?: string;
    /** Url of the image */
    imgUrl?: string;
    /** Users name. Used for fallback initials and alt tag of image. */
    name?: string;
    /** Display size */
    size?: ResponsiveProp<AvatarSize>;
}

export default function Avatar({ className, imgUrl, name = '', size = 'sm' }: AvatarProps) {
    const initials = getInitials(name);

    const sizeClasses = useMemo(() => {
        return resolveResponsiveClasses<AvatarSize>({ propValue: size, baseClass: 'avatar' });
    }, [size]);

    return (
        <div className={cx('avatar', sizeClasses, className)} data-testid="avatar">
            {imgUrl ? (
                <img alt={name} className={classes.avatar__img} src={imgUrl} />
            ) : (
                <div className={classes.avatar__initials}>{initials}</div>
            )}
        </div>
    );
}
