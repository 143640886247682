// TODO: Evaluate if these lint errors are useful
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames/bind';
import { MouseEvent, PropsWithChildren, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { CloseIcon } from '../../icons';
import classes from './Modal.module.scss';

const cx = classNames.bind(classes);

export interface ModalProps {
    /** Add additional css classes */
    className?: string;
    /** A `ReactNode` to render a sticky footer */
    footer?: ReactNode;
    /** Add additional css id */
    id?: string;
    /** Remove the default padding */
    noPadding?: boolean;
    /** Callback that's called when the close button or backdrop is clicked */
    onClose?: () => void;
    /** Wether the modal is visible or not */
    showModal: boolean;
}

export default function Modal({
    children,
    className,
    footer,
    id,
    noPadding = false,
    onClose,
    showModal,
}: PropsWithChildren<ModalProps>) {
    if (!showModal) {
        return null;
    }

    // Prevent modal closing if clicking inside
    const handleContentClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    const handleClose = (e: MouseEvent) => {
        e.stopPropagation();

        if (onClose) {
            onClose();
        }
    };

    return createPortal(
        <div
            className={classes.backdrop}
            data-testid="modal-backdrop"
            onClick={handleClose}
            role="button"
        >
            <div className={cx(classes.modal, className)} data-testid="modal" id={id}>
                <button aria-label="Close Modal" className={classes.close} onClick={handleClose}>
                    <CloseIcon aria-hidden={true} height={16} width={16} />
                </button>
                <div
                    className={cx('body', {
                        'body--padded': !noPadding,
                    })}
                    data-testid="body"
                    onClick={handleContentClick}
                >
                    {children}
                </div>
                {footer ? <div className={classes.footer}>{footer}</div> : null}
            </div>
        </div>,
        document.body
    );
}
