import classNames from 'classnames/bind';
import { ChangeEventHandler, ComponentProps, FocusEventHandler, forwardRef } from 'react';

import InputGroup, { InputGroupProps } from '../InputGroup';
import classes from './RadioButtonTagGroup.module.scss';
import RadioTag from './RadioTag';

const cx = classNames.bind(classes);

export interface TagOption {
    label: string;
    value: string;
}

export interface RadioButtonTagGroupProps extends Omit<InputGroupProps, 'children' | 'className'> {
    /** Align the options, label and error message to be aligned center */
    align?: 'center';
    /** Additional CSS classes */
    className?: string;
    /** Is the radio group in a disabled state */
    disabled?: boolean;
    /** onBlur event handler */
    onBlur?: FocusEventHandler<HTMLInputElement>;
    /** onChange event handler */
    onChange?: ChangeEventHandler<HTMLInputElement>;
    /** Selectable options */
    options: TagOption[];
    /** Render function to customize rendering the RadioTag */
    renderTag?: (props: ComponentProps<typeof RadioTag>) => JSX.Element;
    /** Current value that should display as selected */
    selected?: string;
}

const RadioButtonTagGroup = forwardRef<HTMLInputElement, RadioButtonTagGroupProps>(
    (
        {
            align,
            className,
            disabled,
            errors,
            label,
            name,
            onBlur,
            onChange,
            options,
            renderTag,
            required,
            selected,
        },
        ref
    ) => {
        return (
            <InputGroup
                className={cx({ 'label-align-center': align === 'center' })}
                errors={errors}
                label={label}
                name={name}
                required={required}
            >
                {({ errorId, isInvalid, labelId }) => (
                    <div
                        aria-labelledby={labelId}
                        className={cx(
                            classes.group,
                            {
                                'group--center': align === 'center',
                                'group--invalid': isInvalid,
                            },
                            className
                        )}
                        role="radiogroup"
                    >
                        {options.map((option) => {
                            const { label, value } = option;

                            const tagProps = {
                                'aria-describedby': errorId,
                                defaultChecked: value === selected,
                                disabled,
                                key: value,
                                label,
                                name,
                                onBlur,
                                onChange,
                                ref,
                                required,
                                value,
                            };

                            if (renderTag) {
                                return renderTag(tagProps);
                            }

                            // TS needs us to be explicate about the key prop for some reason
                            return <RadioTag {...tagProps} key={value} />;
                        })}
                    </div>
                )}
            </InputGroup>
        );
    }
);

export default RadioButtonTagGroup;
