import classNames from 'classnames/bind';
import { createElement, MouseEventHandler } from 'react';

import classes from './Badge.module.scss';

const cx = classNames.bind(classes);

export type BadgeSize = 'sm' | 'md';

export interface CommonBadgeProps {
    /** Allows for custom styling */
    className?: string;
    /** The element tag the badge should render as */
    element?: 'div' | 'button';
    /** Display text */
    label: string;
    /** Badge size */
    size?: BadgeSize;
    /** Visual appearance of the badge */
    theme?: 'success' | 'warning' | 'danger' | null;
}

type BadgeTypeProps =
    | {
          element?: 'button';
          /** Function to handle onClick event. Only allowed if you pass `button` for the `element` prop */
          onClick: MouseEventHandler<HTMLButtonElement>;
      }
    | {
          element: 'div';
          onClick?: never;
      };

export type BadgeProps = CommonBadgeProps & BadgeTypeProps;

export default function Badge({
    className,
    element = 'div',
    label,
    onClick,
    size = 'sm',
    theme,
}: BadgeProps) {
    return createElement(
        element,
        {
            className: cx(
                classes.badge,
                {
                    [`badge--${theme}`]: !!theme,
                },
                [`badge--${size}`],
                className
            ),
            onClick,
            'data-testid': 'badge',
        },
        label
    );
}
