import { FileRejection } from 'react-dropzone';

import { RejectedFile } from '../../FileDropArea/types';
import { UploadedFile, UploadFailure } from '../types';

function getUnix() {
    return Math.floor(Date.now() / 1000);
}

/**
 * Create an object with a unique key based on current timestamp in case someone tries
 * uploading a file with the same name multiple times.
 */
export default function transformFileToUploadedFile(files: File | File[]): UploadedFile[] {
    // ensure we have an array
    const arrayOfFiles = Array.isArray(files) ? files : [files];

    return arrayOfFiles.map((file) => {
        const unix = getUnix();
        const { name } = file;

        return {
            key: `${unix}-${name}`,
            file,
        };
    });
}

/**
 * Create an object with a unique key based on current timestamp in case someone tries
 * uploading a file with the same name multiple times.
 */
export function transformFileRejectionToRejectedFile(
    files: FileRejection | FileRejection[]
): RejectedFile[] {
    // ensure we have an array
    const arrayOfRejections = Array.isArray(files) ? files : [files];

    return arrayOfRejections.map((rejection) => {
        const unix = getUnix();
        const {
            file: { name },
        } = rejection;

        return {
            key: `${unix}-${name}`,
            ...rejection,
        };
    });
}

/**
 * A helper function to make rejecting a file more
 * developer friendly.
 */
export function transformUploadFailureToRejectedFile(
    failures: UploadFailure | UploadFailure[]
): RejectedFile[] {
    // ensure we have an array
    const arrayOfFailures = Array.isArray(failures) ? failures : [failures];

    const fileRejections = arrayOfFailures.map((failure) => ({
        errors: [{ code: failure.file.name, message: failure.reason }],
        file: failure.file,
    }));

    return transformFileRejectionToRejectedFile(fileRejections);
}
