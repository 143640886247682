import classNames from 'classnames/bind';
import { Fragment, MouseEvent, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ColumnInstance } from 'react-table';

import { useBoundingclientrect, useClickOutside } from '../../../../hooks';
import { GearIcon } from '../../../../icons';
import highlightFoundTermInString from '../../../../lib/highlightFoundString';
import Checkbox from '../../../Checkbox';
import SearchInput from '../../../SearchInput';
import classes from './ColumnSelect.module.scss';

const cx = classNames.bind(classes);

export interface ColumnSelectProps<T extends object = {}> {
    options: ColumnInstance<T>[];
    toggleHideColumn: (columnId: string, value?: boolean) => void;
}

export default function ColumnSelect<T extends object = {}>({
    options,
    toggleHideColumn,
}: ColumnSelectProps<T>) {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { bottom, right } = useBoundingclientrect(buttonRef);

    useClickOutside(dropdownRef, (e) => {
        /**
         * Handles a weird case where useClickOutside is called when clicking
         * in the menu because it's rendered in a portal and seemingly is
         * seen as an outside element instead of a clild of dropdownRef
         */
        if (!menuRef.current?.contains(e.target as Node)) {
            setIsOpen(false);
        }
    });

    const filteredOptions = useMemo(() => {
        return options.filter((option) => {
            const { Header } = option;

            // Filter out any columns that don't have strings for headers
            if (typeof Header !== 'string') {
                return false;
            }

            // If we aren't using search to widdle down the list, just return all the columns
            if (searchTerm.length < 1) {
                return true;
            }

            return Header.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [options, searchTerm]);

    const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        setIsOpen(!isOpen);
    };

    return (
        <div
            className={cx('dropdown', {
                'dropdown--open': isOpen,
            })}
            ref={dropdownRef}
        >
            <button
                aria-controls="columnmenu"
                aria-expanded={isOpen}
                aria-haspopup="menu"
                aria-label="Show or Hide Columns"
                className={classes['menu-toggle']}
                id="columnmenubtn"
                onClick={handleOnClick}
                ref={buttonRef}
            >
                <GearIcon aria-hidden height={18} width={16} />
            </button>
            {isOpen
                ? createPortal(
                      <div
                          aria-labelledby="columnmenubtn"
                          className={classes.menu}
                          id="columnmenu"
                          ref={menuRef}
                          role="menu"
                          style={{
                              // 4 = 0.25rem, 300 = menu width
                              top: bottom + 4,
                              left: right - 304,
                          }}
                      >
                          <div className={classes['search-wrapper']}>
                              <SearchInput
                                  clearable
                                  hideLabel
                                  label="Search Available Columns"
                                  name="search-columns"
                                  onChange={(e) => {
                                      setSearchTerm(e.target.value);
                                  }}
                                  onClear={() => {
                                      setSearchTerm('');
                                  }}
                                  placeholder="Search Columns"
                                  ref={inputRef}
                              />
                          </div>
                          {filteredOptions.map((option) => {
                              const { Header, id, isVisible } = option;
                              const formattedLabel = highlightFoundTermInString(
                                  searchTerm,
                                  Header as string,
                                  classes.highlight
                              );

                              return (
                                  <Checkbox
                                      className={classes['menu-item']}
                                      defaultChecked={isVisible}
                                      key={id}
                                      name={id}
                                      onChange={(e) => {
                                          e.stopPropagation();

                                          toggleHideColumn(id, !e.target.checked);
                                      }}
                                      value=""
                                  >
                                      {formattedLabel.map((label, index) => (
                                          <Fragment key={index}>{label}</Fragment>
                                      ))}
                                  </Checkbox>
                              );
                          })}
                      </div>,
                      document.body
                  )
                : null}
        </div>
    );
}
