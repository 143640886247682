import { ValidationErrors } from '../types';

interface Params {
    errors?: ValidationErrors;
    name: string;
}

export function checkForInputErrors({ errors, name }: Params) {
    // In the case of useFieldArray, names and errors will include nesting e.g. "products.0.id"
    let inputName = name;

    if (name.includes('.')) {
        const pathParts = name.split('.');
        inputName = pathParts[pathParts.length - 1];
    }

    const error = errors?.[inputName];

    return {
        isInvalid: !!error,
        error,
        errorId: `err-${inputName}`,
    };
}
