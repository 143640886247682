import classNames from 'classnames/bind';
import { cloneElement, forwardRef, MouseEventHandler, ReactElement, useMemo } from 'react';

import { IconProps } from '../../icons';
import { resolveResponsiveClasses } from '../../lib/cssClassResolver';
import type { BtnSize, CommonBtnProps as BtnProps } from '../Button';
import Button from '../Button';
import classes from './IconButton.module.scss';

const cx = classNames.bind(classes);

export interface IconButtonProps extends Omit<BtnProps, 'width'> {
    /** Aria label of the icon for screen readers */
    'aria-label': string;
    /** The icon component to render */
    icon: ReactElement<IconProps>;
    /** Function to handle onClick event */
    onClick: MouseEventHandler<HTMLButtonElement>;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
    (
        {
            'aria-label': ariaLabel,
            className,
            disabled,
            fill,
            icon,
            id,
            onClick,
            rounded,
            size = 'sm',
            theme = 'muted',
            ...rest
        },
        ref
    ) => {
        const sizeClasses = useMemo(() => {
            return resolveResponsiveClasses<BtnSize>({ propValue: size, baseClass: 'icon-btn' });
        }, [size]);

        return (
            <Button
                {...rest}
                aria-label={ariaLabel}
                className={cx('icon-btn', sizeClasses, className)}
                disabled={disabled}
                fill={fill}
                id={id}
                onClick={onClick}
                ref={ref}
                rounded={rounded}
                size={null}
                theme={theme}
                type="button"
            >
                {cloneElement(icon, {
                    width: undefined,
                    height: undefined,
                    'aria-hidden': true,
                })}
            </Button>
        );
    }
);

export default IconButton;
