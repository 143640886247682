import { IconProps } from './types';

export default function HideIcon({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M23.19 10.51a20.64 20.64 0 0 0-4.3-3.59l4-4a.91.91 0 0 0-.05-1.34.87.87 0 0 0-1.31 0L2 21.07a1 1 0 1 0 1.35 1.35l4.15-4.15a10.85 10.85 0 0 0 4.61.94c4.25 0 8.49-3 11-5.8a2.16 2.16 0 0 0 .08-2.9Zm-6.88 2.72a4.59 4.59 0 0 1-3 3 4.3 4.3 0 0 1-3.1-.19.24.24 0 0 1-.06-.4l5.55-5.56a.25.25 0 0 1 .41.06 4.3 4.3 0 0 1 .2 3.09ZM7.61 13.36a.24.24 0 0 0 .07-.24A4 4 0 0 1 7.5 12 4.51 4.51 0 0 1 12 7.46a4 4 0 0 1 1.16.18.26.26 0 0 0 .24-.06l1.95-1.95a.26.26 0 0 0-.11-.42 10.58 10.58 0 0 0-3.24-.5c-4.3 0-8.6 3-11.19 5.8a2.16 2.16 0 0 0 0 2.9 21.36 21.36 0 0 0 3.43 3 .26.26 0 0 0 .33 0Z"
                fill="currentColor"
            />
        </svg>
    );
}
