import { PropsWithChildren } from 'react';

import { AnchorProps } from '../Anchor';
import Anchor from '../Anchor/Anchor';

const AnchorExternal = ({ children, href, ...rest }: PropsWithChildren<AnchorProps>) => {
    return (
        <Anchor href={href} {...rest} rel="noreferrer noopener" target="_blank">
            {children}
        </Anchor>
    );
};

export default AnchorExternal;
