export default function IconExpandRight() {
    return (
        <svg
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
        >
            <path
                d="M10.27 18.03a.9.9 0 0 1-.61-.25.88.88 0 0 1 0-1.22l4.61-4.53-4.54-4.54a.86.86 0 0 1 0-1.21.85.85 0 0 1 1.21 0l5.15 5.14a.86.86 0 0 1 0 1.21l-5.15 5.15a.86.86 0 0 1-.67.25Z"
                fill="currentColor"
            />
        </svg>
    );
}
