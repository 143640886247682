import withoutLeadingSlash from '../withoutLeadingSlash';
import withoutTrailingSlash from '../withoutTrailingSlash';

export default function joinUrls(base: string | undefined, url: string | undefined): string {
    if (!base) {
        return url!;
    }
    if (!url) {
        return base;
    }

    base = withoutTrailingSlash(base);
    url = withoutLeadingSlash(url);

    return `${base}/${url}`;
}
