import { toPercent } from '@keyliving/utils';
import classNames from 'classnames/bind';

import classes from './ProgressBar.module.scss';

export interface ProgressBarProps {
    /** Add additional css classes */
    className?: string;
    /** Percentage complete as a decimal e.g. < 1 */
    decimalPercentage: number;
    /** Used for screen readers to connect the label and progress bar */
    id?: string;
    /** Accessible label for screen readers */
    label: string;
}

const cx = classNames.bind(classes);

export default function ProgressBar({
    className,
    decimalPercentage,
    id = 'progress-bar',
    label,
}: ProgressBarProps) {
    return (
        <>
            <label className="sr-only" htmlFor={id}>
                {label}
            </label>

            <div
                aria-valuemax={100}
                aria-valuemin={0}
                aria-valuenow={decimalPercentage * 100}
                className={cx(classes.progress, className)}
                id={id}
                role="progressbar"
            >
                <div
                    className={cx(classes.progress__bar)}
                    style={{ width: toPercent(decimalPercentage) }}
                />
            </div>
        </>
    );
}
