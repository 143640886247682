import {
    flip,
    offset,
    Placement,
    safePolygon,
    shift,
    useClick,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
    useTransitionStyles,
} from '@floating-ui/react';
import classNames from 'classnames/bind';
import { useState } from 'react';
import { createPortal } from 'react-dom';

import { ChevronDown } from '../../icons';
import Button, { CommonBtnProps } from '../Button';
import classes from './DropdownButton.module.scss';

const cx = classNames.bind(classes);

export interface DropdownButtonProps extends CommonBtnProps {
    /** Placement of the dropdown menu. The floating library may overwrite this with collision detection */
    defaultMenuPlacement?: Placement;
    /** Id of the wrapper around the button. NOT on the button. */
    id?: string;
    /** Render prop to render the dropdowns contents */
    renderMenuContent: () => JSX.Element;
}

/**
 * Some aria attributes are missing e.g. aria-controls, aria-labelledby, aria-expanded etc.
 * These are added by the useFloating hook
 */

export default function DropdownButton({
    children,
    defaultMenuPlacement = 'bottom-start',
    id,
    renderMenuContent,
    ...rest
}: DropdownButtonProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { context, floatingStyles, refs } = useFloating<HTMLButtonElement>({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: defaultMenuPlacement,
        middleware: [flip(), offset(8), shift({ padding: 8 })],
    });

    const click = useClick(context);
    const hover = useHover(context, {
        handleClose: safePolygon(),
    });
    const focus = useFocus(context);
    const role = useRole(context, {
        role: 'menu',
    });
    const { isMounted, styles } = useTransitionStyles(context, {
        duration: 200,
        initial: {
            opacity: 0,
        },
    });

    const { getFloatingProps, getReferenceProps } = useInteractions([click, hover, focus, role]);

    return (
        <div className={cx('dropdown')} data-testid="dropdown-wrapper" id={id}>
            <Button
                aria-label="Show or Hide Dropdown"
                fill="outline"
                onClick={() => {
                    // Needed to make TS happy but the useClick hook will handle this
                }}
                ref={refs.setReference}
                type="button"
                {...rest}
                {...getReferenceProps()}
            >
                {children}
                <div aria-hidden className={classes['dropdown-icon']}>
                    <ChevronDown />
                </div>
            </Button>
            {isMounted
                ? createPortal(
                      <div
                          className={classes.menu}
                          ref={refs.setFloating}
                          style={{
                              ...floatingStyles,
                              ...styles,
                          }}
                          {...getFloatingProps()}
                      >
                          {renderMenuContent()}
                      </div>,
                      document.body
                  )
                : null}
        </div>
    );
}
