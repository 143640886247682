import { findCharacterIndices, withoutTrailingSlash } from '@keyliving/utils';

import { HouseIcon } from '../../icons';
import classes from './Breadcrumb.module.scss';
import Crumb from './Crumb';

export interface BreadcrumbProps {
    /** Full folder path to the current folder e.g. `some-folder/another-folder/` */
    fullPath: string;
    /** onClick handler for the crumb button */
    onClick: (path: string) => void;
}

export default function Breadcrumb({ fullPath, onClick }: BreadcrumbProps) {
    const cleanPath = withoutTrailingSlash(fullPath);
    const dirtyPath = `${cleanPath}/`;
    const indicies = findCharacterIndices({ haystack: dirtyPath, needle: '/' });

    return (
        <nav aria-label="Breadcrumb">
            <ol className={classes.wrapper}>
                <li>
                    <Crumb
                        icon={<HouseIcon />}
                        isLastCrumb={cleanPath.length === 0}
                        label="Home"
                        onClick={onClick}
                        path="/"
                    />
                </li>

                {cleanPath.length > 0
                    ? indicies.map((indicie, index) => {
                          const subPath = fullPath.substring(0, indicie);
                          const isLastCrumb = index === indicies.length - 1;

                          return (
                              <li key={subPath}>
                                  <Crumb
                                      isLastCrumb={isLastCrumb}
                                      onClick={onClick}
                                      path={subPath}
                                  />
                              </li>
                          );
                      })
                    : null}
            </ol>
        </nav>
    );
}
