import { MouseEventHandler, ReactNode } from 'react';

import { ChevronDown, ChevronUp } from '../../../icons';
import classes from '../ConfigurableTable.module.scss';

interface ExpandToggleProps {
    /** Does the row have subRows and can Expand */
    canExpand?: boolean;
    /** A valid ReactNode */
    children?: ReactNode;
    /** Depth of nesting - used to add padding */
    depth: number;
    /** Is the row currently expanded */
    isExpanded?: boolean;
    /** onClick handler for the toggle button */
    onClick: MouseEventHandler<HTMLButtonElement>;
}

export default function ExpandableRowToggle({
    canExpand = false,
    children,
    depth,
    isExpanded = false,
    onClick,
}: ExpandToggleProps) {
    const Icon = isExpanded ? ChevronUp : ChevronDown;

    return (
        <div
            className={classes['nested-row']}
            style={{
                paddingLeft: `${depth * 2}rem`,
            }}
        >
            {children}
            {canExpand ? (
                <button
                    aria-label={isExpanded ? 'Collapse Row' : 'Expand Row'}
                    className={classes['expand-toggle']}
                    onClick={onClick}
                    type="button"
                >
                    <Icon aria-hidden height={8} width={14} />
                </button>
            ) : null}
        </div>
    );
}
