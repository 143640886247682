import classNames from 'classnames/bind';
import { cloneElement, MouseEventHandler, ReactElement, ReactNode } from 'react';

import { ExitIcon } from '../../../icons';
import useSideMenu from '../hooks/useSideMenu';
import ExpandCollapse from './ExpandCollapse';
import classes from './Menu.module.scss';
import MenuItem, { MenuItemProps } from './MenuItem';

const cx = classNames.bind(classes);

export interface MenuProps {
    /** A valid ReactNode */
    children?: ReactNode;
    /** Add additional css classes */
    className?: string;
    /** Display the menu with alternate styles */
    darkTheme?: boolean;
    /** Add a css id */
    id?: string;
    /** An array used to build the Menu Items */
    menuRoutes: MenuItemProps[];
    /** Event handler when the SignOut button is clicked */
    onSignOut: MouseEventHandler<HTMLButtonElement>;
    /** Render a component to use instead of the built in ExpandCollapse toggle */
    renderExpandCollapse?: (props: { menuExpanded: boolean; menuOpen: boolean }) => JSX.Element;
    /** An array of MenuItem's displayed at the bottom of the menu bar */
    utilityMenuItems?: ReactElement<MenuItemProps>[];
}

export default function Menu({
    children,
    className,
    darkTheme = false,
    id,
    menuRoutes,
    onSignOut,
    renderExpandCollapse,
    utilityMenuItems,
}: MenuProps) {
    const { menuExpanded, menuOpen } = useSideMenu();
    const expandCollapseToggle = renderExpandCollapse ? (
        renderExpandCollapse({ menuExpanded, menuOpen })
    ) : (
        <ExpandCollapse />
    );

    return (
        <>
            <div
                className={cx(
                    'nav-wrapper',
                    {
                        'nav-wrapper--open': menuOpen,
                        'theme--dev': darkTheme,
                    },
                    className
                )}
                data-testid="sidemenu-wrapper"
                id={id}
            >
                <nav
                    aria-label="Main Menu"
                    className={cx('nav', {
                        'nav--expanded': menuExpanded,
                    })}
                    id="main-menu"
                    role="navigation"
                >
                    <ul className={cx('menu')} role="menu">
                        {menuRoutes.map((item) => {
                            const { children, icon, label, path, showInMenu } = item;

                            if (showInMenu) {
                                return (
                                    <MenuItem
                                        // eslint-disable-next-line react/no-children-prop
                                        children={children}
                                        collapsible
                                        icon={icon}
                                        key={label}
                                        label={label}
                                        path={path}
                                    />
                                );
                            }

                            return null;
                        })}
                    </ul>

                    <ul className={cx('menu')} role="menu">
                        {utilityMenuItems?.map((item, index) => {
                            return cloneElement(item, {
                                className: cx(item.props.className, classes['menu__item--utility']),
                                key: index,
                            });
                        })}
                        <li>
                            <div className={cx('menu__item', 'menu__item--logout')}>
                                <button
                                    className={cx('navlink')}
                                    onClick={onSignOut}
                                    role="menuitem"
                                    type="button"
                                >
                                    <div aria-hidden>
                                        <ExitIcon height={24} width={24} />
                                    </div>
                                    <div className={cx('navlink__label')}>Log Out</div>
                                </button>
                            </div>
                        </li>
                    </ul>
                </nav>

                {expandCollapseToggle}
            </div>
            {children}
        </>
    );
}
