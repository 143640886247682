import { LoadingRing } from '@keyliving/component-lib';
import { ReactNode } from 'react';

import classes from './FullScreenFallbackLayout.module.scss';

interface FullScreenFallbackLayoutProps {
    children?: ReactNode;
    fallback?: JSX.Element;
    isLoading?: boolean;
}

export default function FullScreenFallbackLayout({
    children,
    fallback,
    isLoading,
}: FullScreenFallbackLayoutProps) {
    if (fallback && isLoading) {
        return fallback;
    }

    return (
        <div className={classes.wrapper}>
            {isLoading ? (
                <div className={classes['loading-indicator']}>
                    <LoadingRing />
                </div>
            ) : (
                children
            )}
        </div>
    );
}
