import { IconProps } from './types';

export default function ChevronDown({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 12.44"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M12 12.44a1.49 1.49 0 0 1-1-.44L.27 1.28A.75.75 0 0 1 1.33.22l10.72 10.72L22.77.22a.77.77 0 0 1 1.07 0 .75.75 0 0 1 0 1.06L13.07 12a1.53 1.53 0 0 1-1.07.44Z"
                fill="currentColor"
            />
        </svg>
    );
}
