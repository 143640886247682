import { withoutLeadingSlash, withoutTrailingSlash } from '@keyliving/utils';
import classNames from 'classnames/bind';
import { ReactElement, useMemo, useState } from 'react';
import { IndexRouteObject, NavLink, NonIndexRouteObject, useLocation } from 'react-router-dom';

import { ChevronDown, ChevronUp } from '../../../icons';
import IconButton from '../../IconButton';
import { isActiveRoute } from '../lib';
import classes from './Menu.module.scss';

const cx = classNames.bind(classes);

interface Props {
    /** Add additional css classes */
    className?: string;
    /** Should a collapse indicator be shown for this MenuItem */
    collapsible?: boolean;
    /** A valid ReactElement to display as an icon to the left of the MenuItem */
    icon?: ReactElement;
    /** The label text for the MenuItem */
    label?: string | null;
    /** Render prop if you want full control over rendering the menu items */
    render?: (props: RenderProps) => JSX.Element;
    /** Should this MenuItem be displayed in the menu */
    showInMenu?: boolean;
}

interface IndexRoute extends IndexRouteObject, Props {
    /** Sub menu items to display for this MenuItem */
    children?: undefined;
}
interface NonIndexRoute extends NonIndexRouteObject, Props {
    /** Sub menu items to display for this MenuItem */
    children?: MenuItemProps[];
}

export type MenuItemProps = IndexRoute | NonIndexRoute;

type RenderProps = Omit<MenuItemProps, 'render'>;

export default function MenuItem(props: MenuItemProps) {
    const { icon, label, path: parentPath, children = [], render, className, collapsible } = props;
    /**
     * NOTE: Can't use window.location.pathname here because that only
     * works in a browser. Won't work if using a MemoryRouter
     * for testing.
     */
    const { pathname } = useLocation();
    const resolvedPath = `/${withoutLeadingSlash(parentPath || '/')}`;
    const isActive = isActiveRoute(pathname, resolvedPath);
    const [isOpen, setIsOpen] = useState(isActive);

    const childrenToShow = useMemo(() => {
        return children.filter((child) => !!child.showInMenu);
    }, [children]);

    if (!parentPath) {
        return null;
    }

    if (render) {
        return render(props);
    }

    return (
        <li>
            <div
                className={cx('menu__item', className, {
                    'menu__item--active': isActive,
                })}
            >
                <NavLink className={cx('navlink')} role="menuitem" to={parentPath}>
                    {icon && <div aria-hidden>{icon}</div>}
                    <div className={cx('navlink__label')}>{label}</div>
                </NavLink>
                {childrenToShow.length > 0 && collapsible ? (
                    <div className={cx('navlink-toggle')}>
                        <IconButton
                            aria-label="Toggle Sub Menu"
                            icon={isOpen ? <ChevronUp /> : <ChevronDown />}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsOpen(!isOpen);
                            }}
                            size="xs"
                            theme={null}
                        />
                    </div>
                ) : null}
            </div>
            {childrenToShow.length > 0 ? (
                <ul
                    className={cx('sub-menu', {
                        'sub-menu--open': isOpen,
                    })}
                    data-testid="sub-menu"
                >
                    {childrenToShow.map(({ label, path }, index) => {
                        let childPath = path ? withoutLeadingSlash(path) : undefined;

                        if (childPath && !childPath.startsWith(withoutLeadingSlash(parentPath))) {
                            childPath = [withoutTrailingSlash(parentPath), childPath].join('/');
                        }

                        return <MenuItem key={`${path}-${index}`} label={label} path={childPath} />;
                    })}
                </ul>
            ) : null}
        </li>
    );
}
