import { PropsWithChildren } from 'react';

import classes from './Whoops.module.scss';

interface WhoopsProps {
    message?: string;
    status?: number;
}

export default function Whoops({
    children,
    message = 'Whoops, something went wrong.',
    status,
}: PropsWithChildren<WhoopsProps>) {
    return (
        <div className={classes.wrapper}>
            {status && <div className={classes.status}>{status}</div>}
            <h1 className={classes.message}>{message}</h1>
            {children}
        </div>
    );
}
