import { TOAST_TYPE, ToastProps } from './Toast';

type Notification = Omit<ToastProps, 'dismiss'>;

export interface ToastContextData {
    dismiss: (id: string) => void;
    error: (message: string) => void;
    info: (message: string) => void;
    notifications: Notification[];
    success: (message: string) => void;
}

export type State = {
    notifications: Notification[];
};

export enum Actions {
    ADD,
    REMOVE,
}

export type Action =
    | { payload: { message: string; type: TOAST_TYPE }; type: Actions.ADD }
    | { payload: { id: string }; type: Actions.REMOVE };
