import classNames from 'classnames/bind';
import { MouseEventHandler, ReactNode } from 'react';

import Button from '../Button';
import classes from './FileUpload.module.scss';

const cx = classNames.bind(classes);

export interface FileListProps<T> {
    children: (files: T[], onRemoveFile: (file: T) => void) => ReactNode;
    className?: string;
    files: T[];
    onClear: MouseEventHandler<HTMLButtonElement>;
    onRemoveFile: (file: T) => void;
    title?: string;
}

export default function FileList<T>({
    files = [],
    onRemoveFile,
    onClear,
    className,
    children,
    title = 'Files',
}: FileListProps<T>) {
    return (
        <div className={cx('file-list', className)} data-testid="file-list">
            <div className={classes['file-list__heading']}>
                <div className={classes['file-list__title']}>{title}</div>
                {files.length > 1 ? (
                    <Button fill="transparent" onClick={onClear} size="sm" theme="tertiary">
                        Clear All
                    </Button>
                ) : null}
            </div>
            {children(files, onRemoveFile)}
        </div>
    );
}
