import { ChangeEventHandler, FocusEventHandler, forwardRef, TextareaHTMLAttributes } from 'react';

import InputGroup, { InputGroupProps } from '../InputGroup';
import InputWrapper from '../InputWrapper';
import classes from './Textarea.module.scss';

type NativeInputProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'name'>;

export interface TextareaProps
    extends NativeInputProps,
        Omit<InputGroupProps, 'children' | 'className'> {
    /** Default value of the textarea */
    defaultValue?: string;
    /** Is the input disabled */
    disabled?: boolean;
    /** Function to be called on the onBlur event */
    onBlur?: FocusEventHandler<HTMLTextAreaElement>;
    /** Function to be called on the onChange event */
    onChange?: ChangeEventHandler<HTMLTextAreaElement>;
    /** Placeholder text of the input */
    placeholder?: string;
    /** Set required attribute of the input and * beside label */
    required?: boolean;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
    (
        {
            caption,
            defaultValue,
            disabled,
            errors,
            hideLabel,
            label,
            name,
            onBlur,
            onChange,
            placeholder,
            required,
            rows = 5,
            secondaryLabel,
            ...rest
        },
        ref
    ) => {
        return (
            <InputGroup
                caption={caption}
                errors={errors}
                hideLabel={hideLabel}
                label={label}
                name={name}
                required={required}
                secondaryLabel={secondaryLabel}
            >
                {({ errorId, isInvalid, labelId }) => (
                    <InputWrapper disabled={disabled} invalid={isInvalid}>
                        <textarea
                            aria-describedby={errorId}
                            aria-invalid={isInvalid}
                            aria-labelledby={labelId}
                            className={classes.input}
                            defaultValue={defaultValue}
                            disabled={disabled}
                            id={name}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder={placeholder}
                            ref={ref}
                            required={required}
                            rows={rows}
                            {...rest}
                        />
                    </InputWrapper>
                )}
            </InputGroup>
        );
    }
);

export default Textarea;
