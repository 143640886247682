import { MenuItemProps } from '@keyliving/component-lib';
import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';

import FullScreenFallbackLayout from '../components/layout/FullScreenFallbackLayout';
import PublicLayout from '../components/layout/PublicLayout';
import NoMatch from '../pages/NoMatch';
import { URLS } from './urls';

const Home = lazy(() => import('../pages/Home'));

/**
 * These are public routes that the app needs. These are used
 * in App.tsx to create these routes.
 */
export const publicRoutes: RouteObject[] = [
    {
        path: '/',
        element: <PublicLayout />,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <Home />
                    </Suspense>
                ),
            },
            {
                path: '*',
                element: <NoMatch />,
            },
        ],
    },
];

/**
 * These are routes for logged in users only. We use this array
 * to create the protected routes in App.tsx.
 */
export const authRoutes: MenuItemProps[] = [
    {
        path: URLS.Placeholder.path,
        label: 'Placeholder',
        showInMenu: false,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <div>Some placeholder for protected routes</div>
                    </Suspense>
                ),
            },
        ],
    },
];
