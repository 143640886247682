import { jsonToCsv } from '@keyliving/utils';
import classNames from 'classnames/bind';
import { type MouseEventHandler, useCallback, useEffect, useReducer } from 'react';

import { useToast } from '../../../Toast';
import classes from './DownloadAsCsv.module.scss';
import { buildURI, createBlobFromCsv, resolveFilename } from './lib';
import { type Action, type State, Actions } from './types';

const cx = classNames.bind(classes);

/**
 * For IE 11 support. TS doesn't support this anymore as it was marked for removal
 * BUT the lib I am basing this off of has it and it might save a future dev
 * some headache.
 */
declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
        msSaveOrOpenBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case Actions.LOADED:
            return {
                ...state,
                isLoading: false,
                csvData: action.payload.csvData,
            };
        default:
            throw Error('unknown action');
    }
}

interface DownloadAsCsvProps {
    fileName?: string;
    json: object[];
}

export default function DownloadAsCsv({ fileName, json }: DownloadAsCsvProps) {
    const [{ csvData, isLoading }, dispatch] = useReducer(reducer, {
        isLoading: true,
        csvData: '',
    });
    const toast = useToast();
    const uri = buildURI(csvData);
    const csvFilename = resolveFilename(fileName);

    const parseJson = useCallback(
        async (data: object[]) => {
            try {
                const csvData = await jsonToCsv(data);

                dispatch({
                    type: Actions.LOADED,
                    payload: {
                        csvData,
                    },
                });
            } catch (error) {
                toast.error('Error parsing data to download CSV');
            }
        },
        [toast]
    );

    /**
     * Only needed for IE 11, modern browsers support the "download" attribute
     */
    const handleClick: MouseEventHandler<HTMLAnchorElement> = async (event) => {
        // If IE, else...
        if (window.navigator.msSaveBlob) {
            event.preventDefault();
            event.stopPropagation();

            const blob = createBlobFromCsv(csvData);
            window.navigator.msSaveBlob(blob, fileName);
        }

        //... nothing to see here
    };

    useEffect(() => {
        parseJson(json);
    }, [json, parseJson]);

    if (isLoading) {
        return null;
    }

    return (
        <a
            className={cx('csv-download')}
            download={csvFilename}
            href={uri}
            onClick={handleClick}
            target="_self"
        >
            Download as CSV
        </a>
    );
}
