export enum ENVIRONMENT {
    demo = 'demo',
    local = 'local',
    production = 'production',
    staging = 'staging',
}

/**
 * Determine the environment based on the hostname
 *
 * @param {string} hostname Current hostname based on window.location
 */
export function resolveEnvironment(hostname: string): ENVIRONMENT {
    const domain = hostname.toLowerCase();

    switch (true) {
        case domain.includes('localhost'):
            return ENVIRONMENT.local;
        case domain.includes('-demo'):
            return ENVIRONMENT.demo;
        case domain.includes('-staging'):
            return ENVIRONMENT.staging;
        default:
            return ENVIRONMENT.production;
    }
}

/**
 * Given the current hostname, determine if we are in a dev environemnt
 * or in production.
 */
export default function useIsDev() {
    const { hostname } = window.location;
    const environment = resolveEnvironment(hostname);

    return {
        environment,
        isDev: environment !== ENVIRONMENT.production,
    };
}
