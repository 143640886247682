import classNames from 'classnames/bind';

import useSideMenu from '../../hooks/useSideMenu';
import classes from '../Menu.module.scss';
import IconExpandLeft from './IconExpandLeft';
import IconExpandRight from './IconExpandRight';

const cx = classNames.bind(classes);

interface ExpandCollapseProps {
    /** Add additional css classes */
    className?: string;
    /** Add a css id */
    id?: string;
}

export default function ExpandCollapse({ className, id }: ExpandCollapseProps) {
    const { menuExpanded, toggleMenuExpanded } = useSideMenu();

    return (
        <button
            aria-label={menuExpanded ? 'Collapse Menu' : 'Expand Menu'}
            className={cx('expand-collapse', className)}
            id={id}
            onClick={toggleMenuExpanded}
            type="button"
        >
            {menuExpanded ? <IconExpandLeft aria-hidden /> : <IconExpandRight aria-hidden />}
        </button>
    );
}
