import { forwardRef } from 'react';

import BaseInput, { BaseInputProps } from '../BaseInput';

export interface Props {
    /** The maximum value to accept for this input */
    max?: string | number;
    /** The minimum value to accept for this input */
    min?: string | number;
    /** Specifies the granularity that the value must adhere to */
    step?: string;
}

export type NumberInputProps = BaseInputProps & Props;

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
    ({ max, min, step, ...rest }, ref) => {
        return (
            <BaseInput
                {...rest}
                max={max}
                min={min}
                onWheel={(e) => e.currentTarget.blur()} // prevents scrolling from changing number value
                ref={ref}
                step={step}
                type="number"
            />
        );
    }
);

export default NumberInput;
