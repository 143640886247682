import { useMemo } from 'react';
import { IdType, Row } from 'react-table';

import RadioGroup from '../../../RadioGroup';
import { RadioOption } from '../../../RadioGroup/RadioGroup';

interface RadioGroupFilterProps<T extends object = {}> {
    columnId: string;
    currentColumnFilters: { id: IdType<T>; value: string }[];
    label: string;
    preFilteredRows: Row<T>[];
    setColumnFilter: (columnId: IdType<T>, filterValue: string) => void;
    stack?: 'vert' | 'horiz';
}

export default function RadioGroupFilter<T extends object = {}>({
    columnId,
    currentColumnFilters,
    label,
    preFilteredRows,
    setColumnFilter,
    stack = 'vert',
}: RadioGroupFilterProps<T>) {
    // Find the filter value for our columnId
    const columnFilterValue = currentColumnFilters.find(({ id }) => id === columnId)?.value;

    const availableOptions = useMemo<RadioOption[]>(() => {
        const options: string[] = [];

        // Build an array of unique values
        preFilteredRows.forEach((row) => {
            const rowValue = row.values[columnId];

            if (!options.includes(rowValue)) {
                options.push(rowValue);
            }
        });

        return options.sort().map((value) => ({
            label: value,
            value,
        }));
    }, [preFilteredRows, columnId]);

    const updateValue = (value: string) => {
        setColumnFilter(columnId, value);
    };

    return (
        <RadioGroup
            controllable
            label={label}
            name={label}
            onChange={(e) => {
                const { value } = e.target;
                updateValue(value);
            }}
            options={availableOptions}
            selected={columnFilterValue}
            stack={stack}
        />
    );
}
