import { CSSProperties } from 'react';

import classes from './LoadingRing.module.scss';

export interface LoadingRingProps {
    /** px value of the stroke width */
    strokeWidth?: number;
    /** Width in px of the ring */
    width?: number;
}

/**
 * Inspiration taken from Medium article
 * @src https://medium.com/@clg/animated-svg-spinner-8dff32d310fc
 */

export default function LoadingRing({ strokeWidth = 4, width = 40 }: LoadingRingProps) {
    const radius = width / 2 - strokeWidth / 2;

    return (
        <svg
            className={classes.spinner}
            height={width}
            style={{ '--spinner-size': `${width}px` } as CSSProperties}
            viewBox={`0 0 ${width} ${width}`}
            width={width}
        >
            <title>Loading</title>
            <circle
                cx={width / 2}
                cy={width / 2}
                r={radius}
                stroke="currentColor"
                strokeWidth={`${strokeWidth}px`}
            />
        </svg>
    );
}
