import { forwardRef } from 'react';

import BaseInput, { BaseInputProps } from '../BaseInput';

export type TextInputProps = BaseInputProps;

const TextInput = forwardRef<HTMLInputElement, BaseInputProps>((props, ref) => {
    return <BaseInput {...props} ref={ref} type="text" />;
});

export default TextInput;
