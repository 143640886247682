/**
 * React tracks manual DOMNode.value changes as well, so when you do input.
 * value ='foo' and then dispatch a change event React sees two discreet
 * moments there, the first change and then the change via the event, so when
 * your input event fires the value is still 'foo' and React says "I already
 * know about that that value so this must be a duplicate" and swallows the
 * event.
 */

/**
 * Set the value of an input element while still being able
 * to fire an onChage event afterwards.
 *
 * @src https://github.com/facebook/react/issues/10135
 */

export default function setNativeValue(element: HTMLInputElement, value: string) {
    const { set: valueSetter } = Object.getOwnPropertyDescriptor(element, 'value') || {};
    const prototype = Object.getPrototypeOf(element);
    const { set: prototypeValueSetter } = Object.getOwnPropertyDescriptor(prototype, 'value') || {};

    if (prototypeValueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(element, value);
    } else if (valueSetter) {
        valueSetter.call(element, value);
    } else {
        throw new Error('The given element does not have a value setter');
    }
}
