import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { ReactNode } from 'react';

import { SUPPORT_EMAIL } from '../../constants';
import Whoops from '../Whoops';
import classes from './ErrorBoundary.module.scss';

interface Props {
    children?: ReactNode;
}

function Fallback() {
    return (
        <div>
            <Whoops message="Application Error">
                <div className="content">
                    <p>
                        Looks like something went wrong that we can&lsquo;t recover from.
                        <br />
                        If the issue continues, please{' '}
                        <a className={classes.reset} href={`mailto:${SUPPORT_EMAIL}`}>
                            contact support
                        </a>
                        .
                    </p>
                    <p>
                        <a className={classes.reset} href="/">
                            Reset Application
                        </a>
                    </p>
                </div>
            </Whoops>
        </div>
    );
}

export default function ErrorBoundary({ children }: Props) {
    return <SentryErrorBoundary fallback={<Fallback />}>{children}</SentryErrorBoundary>;
}
