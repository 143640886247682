import { IconProps } from './types';

export default function CloseIcon({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M.75 24a.74.74 0 0 1-.53-.22.75.75 0 0 1 0-1.06L22.72.22a.77.77 0 0 1 1.07 0 .75.75 0 0 1 0 1.06L1.28 23.78a.71.71 0 0 1-.53.22Z"
                fill="currentColor"
            />
            <path
                d="M23.25 24a.74.74 0 0 1-.53-.22L.22 1.3A.75.75 0 0 1 1.28.24l22.51 22.5a.75.75 0 0 1-.54 1.28Z"
                fill="currentColor"
            />
        </svg>
    );
}
