import { generateRandomId } from '@keyliving/utils';
import { createContext, ReactNode, useCallback, useReducer } from 'react';
import { createPortal } from 'react-dom';

import Toast, { TOAST_TYPE } from '../Toast';
import classes from '../Toast.module.scss';
import { Action, Actions, State, ToastContextData } from '../types';

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case Actions.ADD:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        id: generateRandomId(),
                        message: action.payload.message,
                        type: action.payload.type,
                    },
                ],
            };
        case Actions.REMOVE:
            return {
                ...state,
                notifications: [
                    ...state.notifications.filter(({ id }) => id !== action.payload.id),
                ],
            };
        default:
            throw Error('unknown action');
    }
}

const defaultState: ToastContextData = {
    notifications: [],
    success: () => {},
    info: () => {},
    error: () => {},
    dismiss: () => {},
};

const ToastContext = createContext<ToastContextData>(defaultState);

export interface ProviderProps {
    /** A valid ReactNode */
    children?: ReactNode;
}

export function ToastProvider({ children }: ProviderProps) {
    const [{ notifications }, dispatch] = useReducer(reducer, { notifications: [] });

    const success = useCallback((message: string) => {
        dispatch({
            type: Actions.ADD,
            payload: {
                message,
                type: TOAST_TYPE.SUCCESS,
            },
        });
    }, []);

    const info = useCallback((message: string) => {
        dispatch({
            type: Actions.ADD,
            payload: {
                message,
                type: TOAST_TYPE.INFO,
            },
        });
    }, []);

    const error = useCallback((message: string) => {
        dispatch({
            type: Actions.ADD,
            payload: {
                message,
                type: TOAST_TYPE.ERROR,
            },
        });
    }, []);

    const dismiss = useCallback((id: string) => {
        dispatch({
            type: Actions.REMOVE,
            payload: {
                id,
            },
        });
    }, []);

    return (
        <ToastContext.Provider value={{ notifications, success, dismiss, info, error }}>
            {children}

            {createPortal(
                <div className={classes.wrapper}>
                    {notifications.map(({ id, ...rest }) => {
                        return <Toast dismiss={dismiss} id={id} key={id} {...rest} />;
                    })}
                </div>,
                document.body
            )}
        </ToastContext.Provider>
    );
}

export default ToastContext;
