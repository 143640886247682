import { IconProps } from './types';

export default function WrenchIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M5.29 24c-.78 0-1.55-.17-2.27-.52-.22-.1-.38-.31-.42-.55s.04-.49.21-.66l1.42-1.42c.3-.3.3-.78 0-1.08a.76.76 0 0 0-1.08 0l-1.42 1.42c-.17.17-.42.25-.66.21a.743.743 0 0 1-.55-.42 5.303 5.303 0 0 1 0-4.57c1.18-2.47 4.04-3.61 6.57-2.71l6.63-6.63c-.47-1.32-.41-2.77.21-4.06C15.19.37 18.36-.76 20.99.5c.22.1.38.31.42.55.04.24-.04.49-.21.66l-1.42 1.42c-.3.3-.3.78 0 1.08s.79.3 1.08 0l1.42-1.43c.17-.17.42-.25.66-.21.24.04.45.2.55.42.69 1.45.69 3.11 0 4.56a5.249 5.249 0 0 1-3.01 2.71c-1.16.41-2.41.41-3.55 0l-6.63 6.63c.47 1.32.4 2.77-.21 4.06a5.3 5.3 0 0 1-3.01 2.72c-.58.21-1.18.31-1.78.31Zm-.54-1.54c.6.09 1.23.03 1.82-.18a3.77 3.77 0 0 0 2.16-1.95c.5-1.04.49-2.23 0-3.27a.73.73 0 0 1 .15-.85l7.34-7.34c.22-.22.57-.28.85-.15.92.44 1.95.49 2.9.15a3.77 3.77 0 0 0 2.16-1.95c.33-.69.44-1.45.33-2.19l-.55.55c-.88.88-2.32.88-3.21 0a2.27 2.27 0 0 1 0-3.2l.55-.55c-1.6-.23-3.25.59-3.98 2.13a3.82 3.82 0 0 0 0 3.27c.14.29.08.63-.15.85l-7.34 7.34c-.22.23-.57.28-.85.15-1.89-.9-4.16-.1-5.07 1.79-.33.69-.44 1.45-.33 2.19l.55-.55c.88-.88 2.32-.88 3.21 0 .88.88.88 2.32 0 3.21l-.55.55Z"
                fill="currentColor"
            />
        </svg>
    );
}
