import { useMemo } from 'react';
import { IdType, Row } from 'react-table';

import CheckboxGroup from '../../../CheckboxGroup';
import { CheckboxOption } from '../../../CheckboxGroup/CheckboxGroup';

interface CheckboxGroupFilterProps<T extends object = {}> {
    columnId: string;
    currentColumnFilters: { id: IdType<T>; value: string[] }[];
    label: string;
    preFilteredRows: Row<T>[];
    setColumnFilter: (columnId: IdType<T>, filterValue: string[]) => void;
    stack?: 'vert' | 'horiz';
}

export default function CheckboxGroupFilter<T extends object = {}>({
    columnId,
    currentColumnFilters,
    label,
    preFilteredRows,
    setColumnFilter,
    stack = 'vert',
}: CheckboxGroupFilterProps<T>) {
    // Find the filter value for our columnId
    const columnFilterValue = currentColumnFilters.find(({ id }) => id === columnId)?.value;

    const availableOptions = useMemo<CheckboxOption[]>(() => {
        const options: string[] = [];

        // Build an array of unique values
        preFilteredRows.forEach((row) => {
            const rowValue = row.values[columnId];

            if (!options.includes(rowValue)) {
                options.push(rowValue);
            }
        });

        return options.sort().map((value) => ({
            label: value,
            value,
        }));
    }, [preFilteredRows, columnId]);

    const addValue = (value: string) => {
        // Exit early if the value is already in the filter value
        if (columnFilterValue && columnFilterValue.includes(value)) {
            return;
        }

        // Add to or create our new array of filter values
        const newValue = columnFilterValue ? [...columnFilterValue, value] : [value];
        setColumnFilter(columnId, newValue);
    };

    const removeValue = (value: string) => {
        // Exit early is we don't already have a filter value set
        if (!columnFilterValue) {
            return;
        }

        // Filter out the value in question
        const newValue = columnFilterValue.filter((item) => item !== value);
        setColumnFilter(columnId, newValue);
    };

    return (
        <CheckboxGroup
            controllable
            label={label}
            name={label}
            onChange={(e) => {
                const { checked, value } = e.target;
                const action = checked ? addValue : removeValue;
                action(value);
            }}
            options={availableOptions}
            selected={columnFilterValue}
            stack={stack}
        />
    );
}
