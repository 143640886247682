import classnames from 'classnames/bind';
import { useRef } from 'react';
import { useAsyncDebounce } from 'react-table';

import SearchInput from '../../../../SearchInput';
import classes from './GlobalSearch.module.scss';

const cx = classnames.bind(classes);

interface GlobalSearchProps {
    currentGlobalFilterValue?: any;
    setGlobalFilterValue: (filterValue: any) => void;
    spaceBelow?: boolean;
}

export default function GlobalSearch({
    currentGlobalFilterValue,
    setGlobalFilterValue,
    spaceBelow = false,
}: GlobalSearchProps) {
    const searchInput = useRef<HTMLInputElement>(null);

    const handleSearch = useAsyncDebounce((value) => {
        setGlobalFilterValue(value || undefined);
    }, 200);

    return (
        <div
            className={cx({
                'space-below': spaceBelow,
            })}
        >
            <SearchInput
                clearable
                defaultValue={currentGlobalFilterValue}
                hideLabel
                label="Search"
                name="search"
                onChange={(e) => {
                    const value = e.target.value;
                    handleSearch(value);
                }}
                onClear={() => {
                    setGlobalFilterValue(undefined);
                }}
                ref={searchInput}
            />
        </div>
    );
}
