import classNames from 'classnames/bind';
import { AriaAttributes, ChangeEventHandler, FocusEventHandler, forwardRef } from 'react';

import classes from './Radio.module.scss';

const cx = classNames.bind(classes);

export interface CommonProps extends AriaAttributes {
    /** Is the radio buttons initial state checked. */
    defaultChecked?: boolean;
    /** Is the radio button in a disabled state */
    disabled?: boolean;
    /* Label of the radio button */
    label: string;
    /* Name of the radio button */
    name: string;
    /** Function to be called on the onBlur event */
    onBlur?: FocusEventHandler<HTMLInputElement>;
    /** Is the radio button required */
    required?: boolean;
    /** Value of the radio button */
    value: string;
}

export interface ControlledRadioProps extends CommonProps {
    /** Is the radio checked */
    checked: boolean;
    /** If the radio is a controlled input - You specify if it's checked */
    controllable: true;
    defaultChecked?: never;
    /** Function to be called on the onChange event */
    onChange: ChangeEventHandler<HTMLInputElement>;
}

export interface UnControlledRadioProps extends CommonProps {
    checked?: never;
    controllable?: false;
    /** Is the radio's initial state checked - UnControlled */
    defaultChecked?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

export type RadioProps = ControlledRadioProps | UnControlledRadioProps;

const Radio = forwardRef<HTMLInputElement, RadioProps>(
    (
        {
            checked,
            controllable = false,
            defaultChecked,
            disabled = false,
            label,
            name,
            onBlur,
            onChange,
            required,
            value,
            ...rest
        },
        ref
    ) => {
        return (
            <label
                className={cx(classes.radio, {
                    'radio--disabled': disabled,
                })}
                data-testid="radio-label"
            >
                <input
                    checked={controllable ? checked : undefined}
                    defaultChecked={!controllable ? defaultChecked : undefined}
                    disabled={disabled}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    required={required}
                    type="radio"
                    value={value}
                    {...rest}
                />
                <span className={classes.radio__label}>{label}</span>
            </label>
        );
    }
);

export default Radio;
