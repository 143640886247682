export interface State {
    csvData: string;
    isLoading: boolean;
}

export enum Actions {
    LOADED,
}

export type Action = { payload: { csvData: string }; type: Actions.LOADED };
