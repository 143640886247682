import {
    ChangeEventHandler,
    FocusEventHandler,
    forwardRef,
    ReactNode,
    SelectHTMLAttributes,
} from 'react';

import ChevronDown from '../../icons/ChevronDown';
import InputGroup, { InputGroupProps } from '../InputGroup';
import InputWrapper from '../InputWrapper';
import { SELECT_PLACEHOLDER } from './constants';
import classes from './Select.module.scss';

export interface SelectOption {
    label: string;
    value: string;
}

type NativeInputProps = SelectHTMLAttributes<HTMLSelectElement>;

export interface CommonProps
    extends Omit<NativeInputProps, 'name'>,
        Omit<InputGroupProps, 'children' | 'className'> {
    /** A valid ReactNode */
    children?: ReactNode;
    /** Allows for custom styling */
    className?: string;
    /** If the component should inject an initial empty option */
    defaultEmpty?: boolean;
    /** Is the select disabled */
    disabled?: boolean;
    /** Function to be called on the onBlur event */
    onBlur?: FocusEventHandler<HTMLSelectElement>;
    /** Array of slectable options */
    options?: SelectOption[];
    /** Label of the initial empty option */
    placeholder?: string;
}

export interface ControlledSelectProps extends CommonProps {
    /** If the Select is a controlled input - You specify the value */
    controllable: true;
    /** Function to be called on the onChange event */
    onChange: ChangeEventHandler<HTMLSelectElement>;
    /** Specified value of the select */
    value?: string;
}

export interface UnControlledSelectProps extends CommonProps {
    controllable?: false;
    /** Default value of the select */
    defaultValue?: string;
    onChange?: ChangeEventHandler<HTMLSelectElement>;
}

export type SelectProps = ControlledSelectProps | UnControlledSelectProps;

const Select = forwardRef<HTMLSelectElement, SelectProps>(
    (
        {
            caption,
            children,
            defaultValue,
            disabled = false,
            errors,
            hideLabel,
            label,
            name,
            onBlur,
            onChange,
            options = [],
            required,
            secondaryLabel,
            placeholder = SELECT_PLACEHOLDER,
            defaultEmpty = false,
            className,
            value,
            controllable = false,
        },
        ref
    ) => {
        return (
            <InputGroup
                caption={caption}
                errors={errors}
                hideLabel={hideLabel}
                label={label}
                name={name}
                required={required}
                secondaryLabel={secondaryLabel}
            >
                {({ errorId, isInvalid, labelId }) => (
                    <InputWrapper className={className} disabled={disabled} invalid={isInvalid}>
                        <select
                            aria-describedby={errorId}
                            aria-invalid={isInvalid}
                            aria-labelledby={labelId}
                            className={classes.select}
                            defaultValue={!controllable ? defaultValue : undefined}
                            disabled={disabled}
                            id={name}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            ref={ref}
                            required={required}
                            value={controllable ? value : undefined}
                        >
                            {defaultEmpty && (
                                // NOTE: disabled = required is so an empty value cant be re-selected. Like a radio button.
                                <option disabled={required} value="">
                                    {placeholder}
                                </option>
                            )}
                            {children}
                            {options.map((option) => {
                                const { label, value } = option;

                                return (
                                    <option key={value} value={value}>
                                        {label}
                                    </option>
                                );
                            })}
                        </select>
                        <div aria-hidden className={classes['select-dropdown-icon']}>
                            <ChevronDown height={8} width={15} />
                        </div>
                    </InputWrapper>
                )}
            </InputGroup>
        );
    }
);

export default Select;
